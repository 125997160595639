import React, { useEffect, useRef, useState } from "react"
import { PageProps } from "gatsby"

import classNames from 'classnames';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { SplitText } from "gsap/SplitText"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
import { StaticImage } from "gatsby-plugin-image"

import "../../styles/mclaren.css"
import ProjectHero from "../../components/ProjectHero"
import ProjectTitle from "../../components/ProjectTitle";


const McLarenPage = (props: PageProps) => {
  const [showDRS, setShowDRS] = useState(false);
  const [showRaceCommentary, setShowRaceCommentary] = useState(false);
  const [showSectors, setShowSectors] = useState(false);

  gsap.config();
  gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger, SplitText);

  ScrollTrigger.defaults({ ignoreMobileResize: true });

  useEffect(() => {
    const blocks = gsap.utils.toArray('.mclaren-content-block');
    blocks.forEach((block, i) => {
        const asset = block.querySelector(".asset");
        
        if (asset) {
          gsap.to(asset, {
            y: () => asset.offsetHeight - block.offsetHeight,
            ease: "none",
            scrollTrigger: {
              trigger: block,
              scrub: true,
              pin: false,
              invalidateOnRefresh: true
            },
          }); 
        }

      gsap.fromTo(
        block,
        { 
          scale: 0.8,
          opacity: 0, 
        },
        {
          scale: 1,
          opacity: 1,
          ease: "none",
          force3D: true,
          scrollTrigger: {
            // pin: parent,
            trigger: block,
            start: "top bottom",
            end: "top center",
            pinType: "transform",
            scrub: 0.8,
          }
        }
      );
    });
    // window.addEventListener('resize', () => activeSlide())
  

    return () => {
      // window.removeEventListener('resize', () => activeSlide())
    };
  }, [])


  return (
    <Layout page="work" route="/portfolio/mclaren-racing">
      <Seo 
        image={'/mclaren/cover.jpg'}
        title="McLaren Racing by Alistair Bancroft"
        description={'TeamStream was developed for McLaren to keep users up-to-date about the team at every step of the Formula 1 journey.'}
      />
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css"
      />
      <div className="mclaren-namespace">
        <ProjectHero
          background="mclaren"
          agency={'ekino'}
          body={[
            "TeamStream was developed for McLaren to keep users up-to-date about the team at every step of the Formula 1 journey.  The revolutionary TeamStream is driven by live data powered through web sockets, enabling users to receive new and dynamic content without the need to manually refresh the website. This cutting-edge technology ensures that fans stay connected in real-time, immersing themselves in a seamless stream of updates, stories, and rich media as they unfold - delivering a truly interactive and engaging experience, keeping fans at the forefront of the action without any interruptions.",
            "The heart of TeamStream lies in its ability to seamless stream updates, stories, rich media, and social integration, all accessible within a single feed. The unique aspect of this platform is that it empowers McLaren to collaborate with its fans and partners in real time, building an experience that resonates with their audience.  Among the many benefits, TeamStream promises to enhance fan experience with seamless integration of social media and additional contextual commentary - promoting and enabling the sharing of user-generated content, fostering a sense of community and camaraderie among fans."
            
          ]}
          engagement={'2019'}
          technologies={'Javascript, Web Sockets'}
          url={{
            href: 'https://www.mclaren.com/racing/',
            label: 'www.mclaren.com',
          }}
          position={'Front End Lead / ekino'}
          project={{
            label: 'McLAREN RACING',
          }}
          title={'Project: McLaren Formula One Teamstream'}
          subtitle={"Engaging fans with real-time updates and interactive experiences on TeamStream"}
        />
        <ProjectTitle
          background="ltMclaren"
          text={{
            title: 'orange',
          }}
            content={[{
              title: 'Real-Time Updates',
              body: [
                "Throughout the course of a race weekend, TeamStream actively streams dynamic commentary, providing fans with live updates and insights as the action unfolds on the track - delivering real-time analysis and play-by-play commentary on the most crucial moments of the race. In addition to traditional media types, the TeamStream seamlessly incorporates social media content, ensuring fans can stay connected to their favorite drivers and the team's behind-the-scenes activities. Real-time updates from drivers' Twitter feeds, Instagram posts showcasing memorable moments, and Facebook updates straight from the pit lane all contribute to creating a vibrant and interactive community for McLaren enthusiasts.  Additionally, as part of the fan-centric approach, TeamStream integrates interactive elements including quizzes and competitions, giving fans the opportunity to provide valuable feedback and engage.",
                "This unique blend of commentary, images, and social media content creates an immersive and interactive experience, ensuring fans stay at the heart of the Formula 1 action throughout the entire race weekend."
              ]
            }]}
          />
<section data-background="ltMclaren" className="bg-black border-b border-black/40 md:border-0 font-sans relative w-full inview">
          <div className="md:h-screen md:min-h-[350px] max-h-[480px] bg-white overflow-hidden relative w-full mclaren-content-block mclaren-content-block--article">
              <div className="h-full p-5 mclaren-content-block__inner w-full">
                <StaticImage placeholder="blurred"
                  alt="McLaren F1"
                  className="md:hidden mb-5"
                  src="../../images/portfolio/mclaren/mclaren--banner-4.jpg"
                />
                <div className="flex">
                  <div className="text-sm text-black/60 text-right w-[70px] md:hidden">
                    <div className="flex flex-col items-end md:hidden">
                      <div className="border-b border-black/60 my-1 w-3"></div>
                      <p className="mb-2">Just now</p>
                    </div>
                  </div>
                  <div className="md:absolute md:bottom-0 md:left-[22.22223%] md:pb-8 pl-5 w-[77.77778%] md:w-[66.66667%] z-20">
                    <div className="md:border-l md:border-mclaren-primary text-black md:text-white md:px-5">
                      <div className="absolute top-0 -left-[180px] text-sm text-white text-right w-[160px]">
                        <div className="flex flex-col items-end">
                          <div className="border-b border-white my-1 w-3"></div>
                          <p className="mb-2">Just now</p>
                        </div>
                      </div>
                      <div className="border-b border-mclaren-primary md:border-0 mb-3 pb-2 md:pb-0 text-xl md:text-3xl lg:text-5xl uppercase">Win signed driver helmets</div>
                      <p className="font-light mb-4 text-sm md:text-lg">We&apos;ve saved the best competition until last</p>
                      <button className="duration-300 font-light text-sm md:text-lg text-mclaren-primary md:text-white hover:text-mclaren-primary tracking-wide transition uppercase">Read more</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden md:block absolute bottom-[10%] h-[125%] left-0 w-full asset">
                <StaticImage placeholder="blurred"
                  alt="McLaren F1"
                  className="h-[150%] object-cover w-full"
                  src="../../images/portfolio/mclaren/mclaren--banner-4.jpg"
                />
              </div>
            </div>
            <div className="bg-black border-b border-black/40 md:border-0 flex overflow-hidden py-10 relative w-full mclaren-content-block mclaren-content-block--quote">
              <div className="h-full px-5 md:px-0 mclaren-content-block__inner w-full">
                <div className="flex">
                  <div className="text-sm text-white text-right w-[70px] md:hidden">
                    <div className="flex flex-col items-end md:hidden">
                      <div className="border-b border-white my-1 w-3"></div>
                      <p className="mb-2">5 mins ago</p>
                    </div>
                  </div>
                  <div className="relative md:ml-[22.22223%] pl-5 w-[77.77778%] md:w-[66.66667%] z-20">
                    <div className="text-white">
                      <div className="absolute hidden md:block top-0 -left-[180px] text-sm text-white text-right w-[160px] z-10">
                        <div className="flex flex-col items-end">
                          <div className="border-b border-white my-1 w-3"></div>
                          <p className="mb-2">5 mins ago</p>
                          <p className="hidden md:block ">Lando Norris<br />Racing driver #4</p>
                        </div>
                      </div>
                      <div className="border-l border-r border-mclaren-primary p-5 relative max-w-[735px]">
                        <div className="absolute bg-mclaren-primary h-px left-0 top-0 w-3"></div>
                        <div className="absolute bg-mclaren-primary h-px right-0 top-0 w-3"></div>
                        <blockquote className="text-xl md:text-2xl text-white uppercase">
                          Awesome. Monaco podium! It&apos;s pretty incredible, I never thought it could happen coming into this weekend at all, so I&apos;m super, super happy. 
                        </blockquote>
                        <div className="absolute bg-mclaren-primary h-px left-0 bottom-0 w-3"></div>
                        <div className="absolute bg-mclaren-primary h-px right-0 bottom-0 w-3"></div>
                      </div>
                      <p className="md:hidden mt-6 text-mclaren-primary text-xs tracking-wider uppercase">Lando Norris, Racing driver #4</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </section>

      <ProjectTitle
      background="ltMclaren"
      text={{
        title: 'orange',
      }}
            content={[{
              title: 'Interactive Experiences',
              body: [
                "The front-end of TeamStream harnesses the power of subtle parallax, inertia, and scale transforms to provide users with an immersive and dynamic experience as they scroll through the platform. These carefully crafted visual effects create the sensation of live content being continuously loaded in. The subtle parallax effect adds depth and dimension to the content, bringing a sense of realism and interactivity to the user interface.", "The inertia effect smoothly carries the content forward, mimicking the fluidity of live updates, while scale transforms elegantly adjust the size of media elements, ensuring a seamless transition between different types of content.","This combination of cutting-edge design techniques not only enhances the aesthetic appeal but also elevates the overall user engagement, making TeamStream an exhilarating and captivating destination for McLaren fans eager to stay connected with their favorite team and drivers.",
              ]
            }]}
          />
       
    
          <section data-background="ltMclaren" className="bg-black border-b border-black/40 md:border-0 font-sans relative w-full inview">
          <div className="md:h-screen md:min-h-[350px] max-h-[480px] bg-white overflow-hidden relative w-full mclaren-content-block mclaren-content-block--article">
              <div className="h-full p-5 mclaren-content-block__inner w-full">
                <StaticImage placeholder="blurred"
                  alt="McLaren F1"
                  className="md:hidden mb-5"
                  src="../../images/portfolio/mclaren/mclaren--banner-6.jpg"
                />
                <div className="flex">
                  <div className="text-sm text-black/60 text-right w-[70px] md:hidden">
                    <div className="flex flex-col items-end md:hidden">
                      <div className="border-b border-black/60 my-1 w-3"></div>
                      <p className="mb-2">Just now</p>
                    </div>
                  </div>
                  <div className="md:absolute md:bottom-0 md:left-[22.22223%] md:pb-8 pl-5 md:pl-0 md:pr-5 w-[77.77778%] md:w-[66.66667%] z-20">
                    <div className="md:border-r md:border-mclaren-primary text-black md:text-white md:text-right md:px-5">
                      <div className="absolute top-0 -left-[180px] text-sm text-white text-right w-[160px]">
                        <div className="flex flex-col items-end">
                          <div className="border-b border-white my-1 w-3"></div>
                          <p className="mb-2">Just now</p>
                        </div>
                      </div>
                      <div className="border-b border-mclaren-primary md:border-0 mb-3 pb-2 md:pb-0 text-xl md:text-3xl lg:text-5xl uppercase">#FANSLIKENOOTHER</div>
                      <p className="font-light mb-4 text-sm md:text-lg">Send your messages of support for the team to be featured here, the McLaren app and the garage</p>
                      <button className="duration-300 font-light text-sm md:text-lg text-mclaren-primary md:text-white hover:text-mclaren-primary tracking-wide transition uppercase">Read more</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden md:block absolute bottom-[10%] h-[125%] left-0 w-full asset">
                <StaticImage placeholder="blurred"
                  alt="McLaren F1"
                  className="h-[150%] object-cover w-full"
                  src="../../images/portfolio/mclaren/mclaren--banner-6.jpg"
                />
              </div>
            </div>
            <div className="md:h-screen md:min-h-[350px] md:max-h-[480px] bg-black hover:bg-black/80 overflow-hidden relative w-full mclaren-content-block mclaren-content-block--tweet">
              <div className="h-full p-5 mclaren-content-block__inner w-full">
                <div className="flex">
                  <div className="text-sm text-white text-right w-[70px] md:hidden">
                    <div className="flex flex-col items-end md:hidden">
                      <div className="border-b border-white my-1 w-3"></div>
                      <p className="mb-2">10 mins ago</p>
                      <svg className="w-5" x="0px" y="0px" viewBox="0 0 20 17">
                        <path fill="#ff9e1b" d="M18,0c1.1,0,2,0.9,2,2v12l-4-4h-2v1c0,1.1-0.9,2-2,2H4l-4,4V5c0-1.1,0.9-2,2-2h4V2c0-1.1,0.9-2,2-2H18z M19,2c0-0.5-0.4-1-1-1H8C7.5,1,7,1.5,7,2v1h5c1.1,0,2,0.9,2,2v4h2c0.3,0,0.5,0.1,0.7,0.3l2.3,2.3V2z"></path>
                      </svg>
                    </div>
                  </div>
                  <div className="md:absolute md:bottom-0 h-full md:left-[22.22223%] pl-5 md:p-0 w-[77.77778%] md:w-[66.66667%] z-20">
                    <div className="h-full text-white md:px-5">
                      <div className="absolute top-5 -left-[180px] text-sm text-white text-right w-[160px]">
                        <div className="flex flex-col items-end">
                          <div className="border-b border-white my-1 w-3"></div>
                          <p className="mb-2">10 mins ago</p>
                          <svg className="w-5" x="0px" y="0px" viewBox="0 0 20 17">
                            <path fill="#ff9e1b" d="M18,0c1.1,0,2,0.9,2,2v12l-4-4h-2v1c0,1.1-0.9,2-2,2H4l-4,4V5c0-1.1,0.9-2,2-2h4V2c0-1.1,0.9-2,2-2H18z M19,2c0-0.5-0.4-1-1-1H8C7.5,1,7,1.5,7,2v1h5c1.1,0,2,0.9,2,2v4h2c0.3,0,0.5,0.1,0.7,0.3l2.3,2.3V2z"></path>
                          </svg>
                        </div>
                      </div>
                      <div className="h-full flex flex-col-reverse md:flex-row">
                        <div className="w-full md:w-3/5">
                          <StaticImage placeholder="blurred"
                            alt="McLaren F1"
                            className="-ml-[110px] md:m-0 h-full object-cover twitter-asset"
                            src="../../images/portfolio/mclaren/mclaren--banner-9.jpg"
                          />
                        </div>
                        <div className="mb-6 md:mb-0 md:py-5 md:px-6 w-full md:w-2/5">
                          <div className="mb-4 text-base">YOU BEAUTY! It&apos;s a podium finish for Lando in Monaco. Well done, team!</div>
                          <div className="float-right md:float-none h-5 w-5">
                            <svg className="w-full" viewBox="0 0 14.5 12">
                              <path fill="#4099FF" d="M2.8,10.6c1.9,0.6,4.7,0.8,7.1-1.6c2.3-2.4,2.5-4.4,2.3-5.6c0-0.2,0.1-0.4,0.2-0.5c0.2-0.1,0.5-0.6,0.8-1.1 c-0.3,0-0.8,0.1-1.3,0.1c-0.2,0-0.3,0-0.4-0.2C11.1,1.3,10.5,1,9.9,1C8.7,1,7.7,2,7.7,3.2c0,0.2,0,0.3,0,0.4c0,0.2,0,0.3-0.1,0.5 C7.5,4.3,7.4,4.3,7.2,4.3C4.3,4.2,2.6,2.9,1.8,2.1c-1.1,4.1,2.8,6.4,3,6.5C4.9,8.7,5,8.8,5,9s0,0.3-0.1,0.4 C4.8,9.4,4.1,10.2,2.8,10.6 M5.2,12c-2.7,0-4.9-1.3-5-1.3C0,10.5-0.1,10.3,0,10.1c0.1-0.2,0.3-0.4,0.6-0.3c1.5,0.2,2.5-0.3,3-0.6 C2.3,8-0.8,5.1,1.1,0.8c0.1-0.2,0.2-0.3,0.4-0.3c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,1.4,2.1,4.6,2.5C6.7,1.4,8.1,0,9.9,0 c0.8,0,1.5,0.3,2.1,0.8c0.9-0.1,1.5-0.1,1.6-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.4,0c0.3,0.1,0.4,0.4,0.3,0.7 c-0.1,0.3-0.6,1.5-1.2,2.1C13.4,5,13,7.2,10.6,9.7C8.9,11.5,7,12,5.2,12"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-black border-b border-black/40 md:border-0 flex overflow-hidden py-10 relative w-full mclaren-content-block mclaren-content-block--quote">
              <div className="h-full px-5 md:px-0 mclaren-content-block__inner w-full">
                <div className="flex">
                  <div className="text-sm text-white text-right w-[70px] md:hidden">
                    <div className="flex flex-col items-end md:hidden">
                      <div className="border-b border-white my-1 w-3"></div>
                      <p className="mb-2">2 hours ago</p>
                    </div>
                  </div>
                  <div className="relative md:ml-[22.22223%] pl-5 w-[77.77778%] md:w-[66.66667%] z-20">
                    <div className="text-white">
                      <div className="absolute hidden md:block top-0 -left-[180px] text-sm text-white text-right w-[160px] z-10">
                        <div className="flex flex-col items-end">
                          <div className="border-b border-white my-1 w-3"></div>
                          <p className="mb-2">2 hours ago</p>
                          <p className="hidden md:block ">Piers Thynne<br />Production Director</p>
                        </div>
                      </div>
                      <div className="border-l border-r border-mclaren-primary p-5 relative max-w-[735px]">
                        <div className="absolute bg-mclaren-primary h-px left-0 top-0 w-3"></div>
                        <div className="absolute bg-mclaren-primary h-px right-0 top-0 w-3"></div>
                        <blockquote className="text-xl md:text-2xl text-white uppercase">
                          The number of new parts on the MCL35M is about the same as when we built the MCL35
                        </blockquote>
                        <div className="absolute bg-mclaren-primary h-px left-0 bottom-0 w-3"></div>
                        <div className="absolute bg-mclaren-primary h-px right-0 bottom-0 w-3"></div>
                      </div>
                      <p className="md:hidden mt-6 text-mclaren-primary text-xs tracking-wider uppercase">Piers Thynne, Production Director</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            

            <div className="md:h-screen md:min-h-[260px] max-h-[360px] bg-white overflow-hidden py-12 md:py-0 relative w-full mclaren-content-block mclaren-content-block--shop">
              <div className="h-full p-5 mclaren-content-block__inner w-full">
                <div className="flex">
                  <div className="bg-black/50 text-sm text-white text-right w-[70px] md:hidden relative z-10">
                    <div className="flex flex-col items-end md:hidden">
                      <div className="border-b border-white mt-5 mb-1 w-3"></div>
                      <p className="mb-2">10 hours ago</p>
                    </div>
                  </div>
                  <div className="bg-black/50 md:bg-transparent md:hover:bg-transparent md:absolute md:top-0 md:bottom-0 flex items-center md:left-[22.22223%] md:my-auto md:pb-5 pl-5 w-[77.77778%] md:w-[66.66667%] z-20">
                    <div className="relative text-white md:px-5">
                      <div className="absolute hidden md:block top-0 -left-[200px] text-sm text-white text-right w-[160px]">
                        <div className="flex flex-col items-end">
                          <div className="border-b border-white my-1 w-3"></div>
                          <p className="mb-2">10 hours ago</p>
                        </div>
                      </div>
                      <div className="md:bg-black/50 md:hover:bg-black/80 duration-300 p-5 -ml-1 md:-ml-5 transition">
                        <div className="mb-2 text-xl md:text-xl lg:text-3xl uppercase">New threads</div>
                        <p className="font-light text-sm md:text-base">Get ready McLaren fans! 2021 merch launches 12 February and McLaren Plus members get 10% off</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="absolute bottom-[10%] h-[125%] left-0 w-full asset">
                <StaticImage placeholder="blurred"
                  alt="McLaren F1"
                  className="h-[150%] object-cover w-full"
                  src="../../images/portfolio/mclaren/mclaren--banner-8.jpg"
                />
              </div>
            </div>
           
            <div className="bg-mclaren-primary border-t border-black/50 py-8 relative w-full mclaren-content-block mclaren-content-block--commentary">
              <div className="md:ml-[22.22222%] px-5">
                <button 
                  className={classNames('border border-white duration-300 font-light py-3 px-5 text-white text-xl md:text-2xl transition uppercase mclaren-button', { 'is-active': showRaceCommentary })}
                  onClick={() => setShowRaceCommentary(!showRaceCommentary)}
                >
                  Race commentary
                  <span></span>
                </button>
              </div>
            </div>

            {showRaceCommentary && (
              <>
              <div className=" bg-[#1D1D1B] hover:bg-black/80 overflow-hidden py-6 relative w-full mclaren-content-block mclaren-content-block--comment">
              <div className="h-full p-5 mclaren-content-block__inner w-full">
                <div className="flex">
                  <div className="text-sm text-white text-right w-[70px] md:hidden">
                    <div className="flex flex-col items-end md:hidden">
                      <div className="border-b border-white my-1 w-3"></div>
                      <p className="mb-2">Just now</p>
                      <svg className="w-5" x="0px" y="0px" viewBox="0 0 20 17">
                        <path fill="#ff9e1b" d="M18,0c1.1,0,2,0.9,2,2v12l-4-4h-2v1c0,1.1-0.9,2-2,2H4l-4,4V5c0-1.1,0.9-2,2-2h4V2c0-1.1,0.9-2,2-2H18z M19,2c0-0.5-0.4-1-1-1H8C7.5,1,7,1.5,7,2v1h5c1.1,0,2,0.9,2,2v4h2c0.3,0,0.5,0.1,0.7,0.3l2.3,2.3V2z"></path>
                      </svg>
                    </div>
                  </div>
                  
                  <div className="relative md:ml-[22.22223%] pl-5 md:pl-0 w-[77.77778%] md:w-[66.66667%] z-20">
                    <div className="h-full text-white">
                      <div className="absolute hidden md:block top-0 -left-[180px] text-sm text-white text-right w-[160px] z-10">
                        <div className="flex flex-col items-end">
                          <div className="border-b border-white my-1 w-3"></div>
                          <p className="mb-2">Just now</p>
                          <svg className="w-5" x="0px" y="0px" viewBox="0 0 20 17">
                            <path fill="#ff9e1b" d="M18,0c1.1,0,2,0.9,2,2v12l-4-4h-2v1c0,1.1-0.9,2-2,2H4l-4,4V5c0-1.1,0.9-2,2-2h4V2c0-1.1,0.9-2,2-2H18z M19,2c0-0.5-0.4-1-1-1H8C7.5,1,7,1.5,7,2v1h5c1.1,0,2,0.9,2,2v4h2c0.3,0,0.5,0.1,0.7,0.3l2.3,2.3V2z"></path>
                          </svg>
                        </div>
                      </div>
                      <div className="h-full">
                        <div className="mb-6 md:mb-0 w-full">
                          <div className="mb-4 text-base">STATUS UPDATE. Chequered flag at the Circuit de Monaco. Lando P3, Daniel P12. Finishing order: 1. VER, 2. SAI, 3. Lando, 4. PER, 5. VET, 6. GAS, 7. HAM, 8. STR, 9. OCO, 10. GIO, 11. RAI, 12. Daniel, 13. ALO, 14. RUS, 15. LAT, 16. TSU, 17. MAZ, 18. MSC.</div>
                          <div className="float-right md:float-none h-5 w-5">
                            <svg className="w-full" viewBox="0 0 14.5 12">
                              <path fill="#4099FF" d="M2.8,10.6c1.9,0.6,4.7,0.8,7.1-1.6c2.3-2.4,2.5-4.4,2.3-5.6c0-0.2,0.1-0.4,0.2-0.5c0.2-0.1,0.5-0.6,0.8-1.1 c-0.3,0-0.8,0.1-1.3,0.1c-0.2,0-0.3,0-0.4-0.2C11.1,1.3,10.5,1,9.9,1C8.7,1,7.7,2,7.7,3.2c0,0.2,0,0.3,0,0.4c0,0.2,0,0.3-0.1,0.5 C7.5,4.3,7.4,4.3,7.2,4.3C4.3,4.2,2.6,2.9,1.8,2.1c-1.1,4.1,2.8,6.4,3,6.5C4.9,8.7,5,8.8,5,9s0,0.3-0.1,0.4 C4.8,9.4,4.1,10.2,2.8,10.6 M5.2,12c-2.7,0-4.9-1.3-5-1.3C0,10.5-0.1,10.3,0,10.1c0.1-0.2,0.3-0.4,0.6-0.3c1.5,0.2,2.5-0.3,3-0.6 C2.3,8-0.8,5.1,1.1,0.8c0.1-0.2,0.2-0.3,0.4-0.3c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,1.4,2.1,4.6,2.5C6.7,1.4,8.1,0,9.9,0 c0.8,0,1.5,0.3,2.1,0.8c0.9-0.1,1.5-0.1,1.6-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.4,0c0.3,0.1,0.4,0.4,0.3,0.7 c-0.1,0.3-0.6,1.5-1.2,2.1C13.4,5,13,7.2,10.6,9.7C8.9,11.5,7,12,5.2,12"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </>
            )}
            <div className=" bg-[#1D1D1B] hover:bg-black/80 overflow-hidden py-6 relative w-full mclaren-content-block mclaren-content-block--comment">
              <div className="h-full p-5 mclaren-content-block__inner w-full">
                <div className="flex">
                  <div className="text-sm text-white text-right w-[70px] md:hidden">
                    <div className="flex flex-col items-end md:hidden">
                      <div className="border-b border-white my-1 w-3"></div>
                      <p className="mb-2">1 min</p>
                      <svg version="1.1" className="w-5" x="0px" y="0px" viewBox="0 0 20 16"><g><defs><rect id="SVGID_1_" x="0" y="0" width="20" height="16"></rect></defs><clipPath id="SVGID_2_"><use xlinkHref="#SVGID_1_" overflow="visible"></use></clipPath><g clipPath="url(#SVGID_2_)"><defs><rect id="SVGID_3_" x="0" y="0" width="20" height="16"></rect></defs><clipPath id="SVGID_4_"><use xlinkHref="#SVGID_3_" overflow="visible"></use></clipPath><path fill="#ff9e1b" clipPath="url(#SVGID_4_)" d="M19,14L19,14l0-0.1v0c0-1.2-0.2-2.5-0.7-3.8C14.8,11.4,9,10,9,10C7.9,9.8,7,8.8,7,7.5C7,6.3,7.9,5.2,9,5l6.2-1c0,0,0,0,0,0C13.6,2.1,11.4,1,9,1C4.6,1,1,4.6,1,9c0,2,0.7,3.9,2,5.3c0.2,0.2,0.4,0.5,0.7,0.7h10.9H18C18.5,15,19,14.5,19,14 M0,9c0-5,4-9,9-9c2.8,0,5.3,1.3,6.9,3.3c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.1,0.1,0.1,0.1,0.2c1.2,1.7,2.2,3.7,2.8,5.6c0.5,1.5,0.7,2.9,0.7,4.2c0,0.1,0,0.1,0,0.2c0,1.1-0.9,2-2,2h-3.4H3.4c-0.4-0.3-0.7-0.6-1.1-1C0.9,13.4,0,11.3,0,9"></path></g></g></svg>
                    </div>
                  </div>
                  
                  <div className="relative md:ml-[22.22223%] pl-5 md:pl-0 w-[77.77778%] md:w-[66.66667%] z-20">
                    <div className="h-full text-white">
                      <div className="absolute hidden md:block top-0 -left-[180px] text-sm text-white text-right w-[160px] z-10">
                        <div className="flex flex-col items-end">
                          <div className="border-b border-white my-1 w-3"></div>
                          <p className="mb-2">1 min</p>

                          <div className="flex items-center text-xs text-white/80 tracking-wider uppercase">
                            <div className="mr-4">
                              <svg version="1.1" className="w-5" x="0px" y="0px" viewBox="0 0 20 16"><g><defs><rect id="SVGID_1_" x="0" y="0" width="20" height="16"></rect></defs><clipPath id="SVGID_2_"><use xlinkHref="#SVGID_1_" overflow="visible"></use></clipPath><g clipPath="url(#SVGID_2_)"><defs><rect id="SVGID_3_" x="0" y="0" width="20" height="16"></rect></defs><clipPath id="SVGID_4_"><use xlinkHref="#SVGID_3_" overflow="visible"></use></clipPath><path fill="#ff9e1b" clipPath="url(#SVGID_4_)" d="M19,14L19,14l0-0.1v0c0-1.2-0.2-2.5-0.7-3.8C14.8,11.4,9,10,9,10C7.9,9.8,7,8.8,7,7.5C7,6.3,7.9,5.2,9,5l6.2-1c0,0,0,0,0,0C13.6,2.1,11.4,1,9,1C4.6,1,1,4.6,1,9c0,2,0.7,3.9,2,5.3c0.2,0.2,0.4,0.5,0.7,0.7h10.9H18C18.5,15,19,14.5,19,14 M0,9c0-5,4-9,9-9c2.8,0,5.3,1.3,6.9,3.3c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.1,0.1,0.1,0.1,0.2c1.2,1.7,2.2,3.7,2.8,5.6c0.5,1.5,0.7,2.9,0.7,4.2c0,0.1,0,0.1,0,0.2c0,1.1-0.9,2-2,2h-3.4H3.4c-0.4-0.3-0.7-0.6-1.1-1C0.9,13.4,0,11.3,0,9"></path></g></g></svg>
                            </div>
                            <div className="mr-2">PIT</div>
                            <div className="mr-2">
                              <svg className="w-8" version="1.1" x="0px" y="0px" viewBox="0 0 27 6.1"><line stroke="#ff9e1b" strokeMiterlimit="10" x1="0" y1="3" x2="24" y2="3"></line><polygon fill="#ff9e1b" points="24,0 24,6.1 27,3 "></polygon></svg>
                            </div>
                            <div>RIC</div>
                          </div>
                        </div>
                      </div>
                      <div className="h-full">
                        <div className="mb-6 md:mb-0 w-full">
                          <div className="mb-4 text-base">OK Daniel. I think after the first lap mate, there were zero overtakes. From where we started the race, it was always going to be tough.</div>
                          <div className="float-right md:float-none h-5 w-5">
                            <svg className="w-full" viewBox="0 0 14.5 12">
                              <path fill="#4099FF" d="M2.8,10.6c1.9,0.6,4.7,0.8,7.1-1.6c2.3-2.4,2.5-4.4,2.3-5.6c0-0.2,0.1-0.4,0.2-0.5c0.2-0.1,0.5-0.6,0.8-1.1 c-0.3,0-0.8,0.1-1.3,0.1c-0.2,0-0.3,0-0.4-0.2C11.1,1.3,10.5,1,9.9,1C8.7,1,7.7,2,7.7,3.2c0,0.2,0,0.3,0,0.4c0,0.2,0,0.3-0.1,0.5 C7.5,4.3,7.4,4.3,7.2,4.3C4.3,4.2,2.6,2.9,1.8,2.1c-1.1,4.1,2.8,6.4,3,6.5C4.9,8.7,5,8.8,5,9s0,0.3-0.1,0.4 C4.8,9.4,4.1,10.2,2.8,10.6 M5.2,12c-2.7,0-4.9-1.3-5-1.3C0,10.5-0.1,10.3,0,10.1c0.1-0.2,0.3-0.4,0.6-0.3c1.5,0.2,2.5-0.3,3-0.6 C2.3,8-0.8,5.1,1.1,0.8c0.1-0.2,0.2-0.3,0.4-0.3c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,1.4,2.1,4.6,2.5C6.7,1.4,8.1,0,9.9,0 c0.8,0,1.5,0.3,2.1,0.8c0.9-0.1,1.5-0.1,1.6-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.4,0c0.3,0.1,0.4,0.4,0.3,0.7 c-0.1,0.3-0.6,1.5-1.2,2.1C13.4,5,13,7.2,10.6,9.7C8.9,11.5,7,12,5.2,12"></path>
                            </svg>
                          </div>
                          <div className="flex md:hidden items-center text-xs text-white/80 tracking-wider uppercase">
                            <div className="mr-2">PIT</div>
                            <div className="mr-2">
                              <svg className="w-8" version="1.1" x="0px" y="0px" viewBox="0 0 27 6.1"><line stroke="#ff9e1b" strokeMiterlimit="10" x1="0" y1="3" x2="24" y2="3"></line><polygon fill="#ff9e1b" points="24,0 24,6.1 27,3 "></polygon></svg>
                            </div>
                            <div>RIC</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className=" bg-[#1D1D1B] hover:bg-black/80 overflow-hidden py-6 relative w-full mclaren-content-block mclaren-content-block--comment">
              <div className="h-full p-5 mclaren-content-block__inner w-full">
                <div className="flex">
                  <div className="text-sm text-white text-right w-[70px] md:hidden">
                    <div className="flex flex-col items-end md:hidden">
                      <div className="border-b border-white my-1 w-3"></div>
                      <p className="mb-2">1 min</p>
                      <svg version="1.1" className="w-5" x="0px" y="0px" viewBox="0 0 20 16"><g><defs><rect id="SVGID_1_" x="0" y="0" width="20" height="16"></rect></defs><clipPath id="SVGID_2_"><use xlinkHref="#SVGID_1_" overflow="visible"></use></clipPath><g clipPath="url(#SVGID_2_)"><defs><rect id="SVGID_3_" x="0" y="0" width="20" height="16"></rect></defs><clipPath id="SVGID_4_"><use xlinkHref="#SVGID_3_" overflow="visible"></use></clipPath><path fill="#ff9e1b" clipPath="url(#SVGID_4_)" d="M19,14L19,14l0-0.1v0c0-1.2-0.2-2.5-0.7-3.8C14.8,11.4,9,10,9,10C7.9,9.8,7,8.8,7,7.5C7,6.3,7.9,5.2,9,5l6.2-1c0,0,0,0,0,0C13.6,2.1,11.4,1,9,1C4.6,1,1,4.6,1,9c0,2,0.7,3.9,2,5.3c0.2,0.2,0.4,0.5,0.7,0.7h10.9H18C18.5,15,19,14.5,19,14 M0,9c0-5,4-9,9-9c2.8,0,5.3,1.3,6.9,3.3c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.1,0.1,0.1,0.1,0.2c1.2,1.7,2.2,3.7,2.8,5.6c0.5,1.5,0.7,2.9,0.7,4.2c0,0.1,0,0.1,0,0.2c0,1.1-0.9,2-2,2h-3.4H3.4c-0.4-0.3-0.7-0.6-1.1-1C0.9,13.4,0,11.3,0,9"></path></g></g></svg>
                    </div>
                  </div>
                  
                  <div className="relative md:ml-[22.22223%] pl-5 md:pl-0 w-[77.77778%] md:w-[66.66667%] z-20">
                    <div className="h-full text-white">
                      <div className="absolute hidden md:block top-0 -left-[180px] text-sm text-white text-right w-[160px] z-10">
                        <div className="flex flex-col items-end">
                          <div className="border-b border-white my-1 w-3"></div>
                          <p className="mb-2">4 min</p>

                          <div className="flex items-center text-xs text-white/80 tracking-wider uppercase">
                            <div className="mr-4">
                              <svg version="1.1" className="w-5" x="0px" y="0px" viewBox="0 0 20 16"><g><defs><rect id="SVGID_1_" x="0" y="0" width="20" height="16"></rect></defs><clipPath id="SVGID_2_"><use xlinkHref="#SVGID_1_" overflow="visible"></use></clipPath><g clipPath="url(#SVGID_2_)"><defs><rect id="SVGID_3_" x="0" y="0" width="20" height="16"></rect></defs><clipPath id="SVGID_4_"><use xlinkHref="#SVGID_3_" overflow="visible"></use></clipPath><path fill="#ff9e1b" clipPath="url(#SVGID_4_)" d="M19,14L19,14l0-0.1v0c0-1.2-0.2-2.5-0.7-3.8C14.8,11.4,9,10,9,10C7.9,9.8,7,8.8,7,7.5C7,6.3,7.9,5.2,9,5l6.2-1c0,0,0,0,0,0C13.6,2.1,11.4,1,9,1C4.6,1,1,4.6,1,9c0,2,0.7,3.9,2,5.3c0.2,0.2,0.4,0.5,0.7,0.7h10.9H18C18.5,15,19,14.5,19,14 M0,9c0-5,4-9,9-9c2.8,0,5.3,1.3,6.9,3.3c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.1,0.1,0.1,0.1,0.2c1.2,1.7,2.2,3.7,2.8,5.6c0.5,1.5,0.7,2.9,0.7,4.2c0,0.1,0,0.1,0,0.2c0,1.1-0.9,2-2,2h-3.4H3.4c-0.4-0.3-0.7-0.6-1.1-1C0.9,13.4,0,11.3,0,9"></path></g></g></svg>
                            </div>
                            <div className="mr-2">NOR</div>
                            <div className="mr-2">
                              <svg className="w-8" version="1.1" x="0px" y="0px" viewBox="0 0 27 6.1"><line stroke="#ff9e1b" strokeMiterlimit="10" x1="0" y1="3" x2="24" y2="3"></line><polygon fill="#ff9e1b" points="24,0 24,6.1 27,3 "></polygon></svg>
                            </div>
                            <div>Pit</div>
                          </div>
                        </div>
                      </div>
                      <div className="h-full">
                        <div className="mb-6 md:mb-0 w-full">
                          <div className="mb-4 text-base">Yeessssssss!!!!!! Monaco! Great job guys, all weekend. Another podium!!!! Two this year. Want at least another! Let&apos;s keep up the hard work!</div>
                          <div className="float-right md:float-none h-5 w-5">
                            <svg className="w-full" viewBox="0 0 14.5 12">
                              <path fill="#4099FF" d="M2.8,10.6c1.9,0.6,4.7,0.8,7.1-1.6c2.3-2.4,2.5-4.4,2.3-5.6c0-0.2,0.1-0.4,0.2-0.5c0.2-0.1,0.5-0.6,0.8-1.1 c-0.3,0-0.8,0.1-1.3,0.1c-0.2,0-0.3,0-0.4-0.2C11.1,1.3,10.5,1,9.9,1C8.7,1,7.7,2,7.7,3.2c0,0.2,0,0.3,0,0.4c0,0.2,0,0.3-0.1,0.5 C7.5,4.3,7.4,4.3,7.2,4.3C4.3,4.2,2.6,2.9,1.8,2.1c-1.1,4.1,2.8,6.4,3,6.5C4.9,8.7,5,8.8,5,9s0,0.3-0.1,0.4 C4.8,9.4,4.1,10.2,2.8,10.6 M5.2,12c-2.7,0-4.9-1.3-5-1.3C0,10.5-0.1,10.3,0,10.1c0.1-0.2,0.3-0.4,0.6-0.3c1.5,0.2,2.5-0.3,3-0.6 C2.3,8-0.8,5.1,1.1,0.8c0.1-0.2,0.2-0.3,0.4-0.3c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,1.4,2.1,4.6,2.5C6.7,1.4,8.1,0,9.9,0 c0.8,0,1.5,0.3,2.1,0.8c0.9-0.1,1.5-0.1,1.6-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.4,0c0.3,0.1,0.4,0.4,0.3,0.7 c-0.1,0.3-0.6,1.5-1.2,2.1C13.4,5,13,7.2,10.6,9.7C8.9,11.5,7,12,5.2,12"></path>
                            </svg>
                          </div>
                          <div className="flex md:hidden items-center text-xs text-white/80 tracking-wider uppercase">
                            <div className="mr-2">NOR</div>
                            <div className="mr-2">
                              <svg className="w-8" version="1.1" x="0px" y="0px" viewBox="0 0 27 6.1"><line stroke="#ff9e1b" strokeMiterlimit="10" x1="0" y1="3" x2="24" y2="3"></line><polygon fill="#ff9e1b" points="24,0 24,6.1 27,3 "></polygon></svg>
                            </div>
                            <div>PIT</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:h-screen md:min-h-[350px] md:max-h-[480px] bg-white relative w-full mclaren-content-block mclaren-content-block--track">
              <div className="h-full px-5 mclaren-content-block__inner w-full">
                <div className="flex h-full">
                  <div className="py-5 text-sm text-black/60 text-right w-[70px] md:hidden">
                    <div className="flex flex-col items-end md:hidden">
                      <div className="border-b border-black/60 my-1 w-3"></div>
                      <p className="mb-2">11/01</p>
                    </div>
                  </div>
                  <div className="md:absolute md:top-0 md:bottom-0 md:left-[22.22223%] md:my-auto pl-5 w-[77.77778%] z-20">
                    <div className="bg-mclaren-secondary h-full text-black md:px-5">
                      <div className="absolute py-5 top-0 -left-[180px] text-sm  text-right w-[160px]">
                        <div className="flex flex-col items-end">
                          <div className="border-b border-black my-1 w-3"></div>
                          <p className="mb-2">11/01</p>
                          <p>Monaco Grand Prix</p>
                        </div>
                      </div>
                      <div className="h-full md:flex p-5 -ml-1 md:-ml-5">
                        <div>
                          <div className="mb-2 text-xl md:text-xl lg:text-3xl uppercase">Circuit de Monaco</div>
                          <p className="hidden md:block font-light mb-12 text-sm md:text-base">23 May 2021</p>

                          <div className="hidden md:flex flex-col">
                            <button
                              className={classNames('mclaren-button-black border border-black duration-300 inline-block w-40 mb-4 py-2 px-4 text-black text-sm transition uppercase', { 'bg-mclaren-tiertiary border-mclaren-tiertiary': showSectors })} 
                              onClick={() => setShowSectors(!showSectors)}
                            >Sectors {showSectors ? 'on' : 'off'}</button>
                            <button
                              className={classNames('mclaren-button-black border border-black duration-300 inline-block w-40 mb-4 py-2 px-4 text-black text-sm transition uppercase', { 'bg-mclaren-tiertiary border-mclaren-tiertiary': showDRS })} 
                              onClick={() => setShowDRS(!showDRS)}
                            >DRS {showDRS ? 'on' : 'off'}</button>
                          </div>
                        </div>
                        <div className="aspect-[600/420] relative max-h-[420px] max-w-[600px] w-full">
                          {showSectors && (
                            <StaticImage placeholder="blurred"
                              alt="McLaren F1"
                              className="absolute aspect-[600/420] w-full z-40"
                              src="../../images/portfolio/mclaren/monaco-sectors.png"
                            />
                          )}
                          {showDRS && (
                            <StaticImage placeholder="blurred"
                              alt="McLaren F1"
                              className="absolute aspect-[600/420] w-full z-50"
                              src="../../images/portfolio/mclaren/monaco-drs.png"
                            />
                          )}
                        
                          <StaticImage placeholder="blurred"
                            alt="McLaren F1"
                            className="absolute aspect-[600/420] w-full z-30"
                            src="../../images/portfolio/mclaren/monaco-b.png"
                          />
                        </div>
                        <div className="flex flex-wrap md:hidden">
                          <button
                            className={classNames('mclaren-button-black border border-black duration-300 inline-block mb-4 mr-6 py-2 px-4 text-black text-xs transition uppercase whitespace-nowrap', { 'bg-mclaren-tiertiary border-mclaren-tiertiary': showSectors })} 
                            onClick={() => setShowSectors(!showSectors)}
                          >Sectors {showSectors ? 'on' : 'off'}</button>
                          <button
                            className={classNames('mclaren-button-black border border-black duration-300 inline-block mb-4 py-2 px-4 text-black text-xs transition uppercase whitespace-nowrap', { 'bg-mclaren-tiertiary border-mclaren-tiertiary': showDRS })} 
                            onClick={() => setShowDRS(!showDRS)}
                          >DRS {showDRS ? 'on' : 'off'}</button>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-black  border-t border-white/40 flex overflow-hidden pt-10 relative w-full mclaren-content-block mclaren-content-block--gallery">
              <div className="h-full mclaren-content-block__inner w-full">
                <div className="flex mb-10 px-5 md:px-0">
                  <div className="text-sm text-white text-right w-[70px] md:hidden">
                    <div className="flex flex-col items-end md:hidden">
                      <div className="border-b border-white my-1 w-3"></div>
                      <p className="mb-2">10/01</p>
                    </div>
                  </div>
                  <div className="relative md:ml-[22.22223%] pl-5 w-[77.77778%] md:w-[66.66667%] z-20">
                    <div className="text-white">
                      <div className="absolute hidden md:block top-0 -left-[180px] text-sm text-white text-right w-[160px] z-10">
                        <div className="flex flex-col items-end">
                          <div className="border-b border-white my-1 w-3"></div>
                          <p className="mb-2">10/01</p>
                        </div>
                      </div>
                      <div className="relative max-w-[735px]">
                        <div className="mb-2 text-xl md:text-xl lg:text-3xl uppercase">THURSDAY IN MONACO</div>
                        <p className="font-light mb-6 text-sm md:text-base">&quot;We&apos;ll get back out there on Saturday and have some fun&quot;</p>
                        <button className="duration-300 font-light text-sm md:text-lg text-mclaren-primary md:text-white hover:text-mclaren-primary tracking-wide transition uppercase">Read more</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery-wrapper">
                <Swiper slidesPerView={'auto'} loop={true} spaceBetween={0} navigation={{
                  nextEl: '.gallery--next',
                  prevEl: '.gallery--prev'
                }} modules={[Navigation]} className="h-full max-h-[340px] md:max-h-[480px]">
                  <SwiperSlide className="w-auto">
                    <StaticImage placeholder="blurred"
                      alt="McLaren F1"
                      className="aspect-[750/480] h-full max-h-[340px] md:max-h-[480px] object-cover"
                      src="../../images/portfolio/mclaren/mclaren--gallery-1.jpg"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="w-auto">
                    <StaticImage placeholder="blurred"
                      alt="McLaren F1"
                      className="aspect-[550/480] h-full max-h-[340px] md:max-h-[480px] object-cover"
                      src="../../images/portfolio/mclaren/mclaren--gallery-2.jpg"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="w-auto">
                    <StaticImage placeholder="blurred"
                      alt="McLaren F1"
                      className="aspect-[400/480] h-full max-h-[340px] md:max-h-[480px] object-cover"
                      src="../../images/portfolio/mclaren/mclaren--gallery-4.jpg"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="w-auto">
                    <StaticImage placeholder="blurred"
                      alt="McLaren F1"
                      className="aspect-[750/480] h-full max-h-[340px] md:max-h-[480px] object-cover"
                      src="../../images/portfolio/mclaren/mclaren--gallery-3.jpg"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="w-auto">
                    <StaticImage placeholder="blurred"
                      alt="McLaren F1"
                      className="aspect-[750/480] h-full max-h-[340px] md:max-h-[480px] object-cover"
                      src="../../images/portfolio/mclaren/mclaren--gallery-5.jpg"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="w-auto">
                    <StaticImage placeholder="blurred"
                      alt="McLaren F1"
                      className="aspect-[750/480] h-full max-h-[340px] md:max-h-[480px] object-cover"
                      src="../../images/portfolio/mclaren/mclaren--gallery-6.jpg"
                    />
                  </SwiperSlide>
                <div className="gallery--next"><span>Next</span></div>
                <div className="gallery--prev"><span>Prev</span></div>
                </Swiper>
                </div>
              </div>
            </div>
            <div className="md:h-screen md:min-h-[350px] max-h-[480px] bg-white overflow-hidden relative w-full mclaren-content-block mclaren-content-block--article">
              <div className="h-full p-5 mclaren-content-block__inner w-full">
                <div className="relative mb-5">
                  <div className="absolute bg-black/40 md:hidden h-full w-full z-20"></div>
                  <button className="absolute border border-white cursor-pointer duration-300 h-12 inline-block md:hidden px-5 text-sm top-0 bottom-0 text-white left-0 right-0 m-auto tracking-wide transition uppercase w-32 mclaren-button z-20">Play Video</button>
                  <StaticImage placeholder="blurred"
                    alt="McLaren F1"
                    className="md:hidden relative z-10"
                    src="../../images/portfolio/mclaren/mclaren--banner-4.jpg"
                  />
                </div>
                <div className="flex">
                  <div className="text-sm text-black/60 text-right w-[70px] md:hidden">
                    <div className="flex flex-col items-end md:hidden">
                      <div className="border-b border-black/60 my-1 w-3"></div>
                      <p className="mb-2">10/01</p>
                    </div>
                  </div>
                  <div className="md:absolute md:bottom-0 md:left-[22.22223%] md:pb-8 pl-5 w-[77.77778%] md:w-[66.66667%] z-20">
                    <div className="md:border-l md:border-mclaren-primary text-black md:text-white md:px-5">
                      <div className="absolute top-0 -left-[180px] text-sm text-white text-right w-[160px]">
                        <div className="flex flex-col items-end">
                          <div className="border-b border-white my-1 w-3"></div>
                          <p className="mb-2">10/01</p>
                        </div>
                      </div>
                      <button className="absolute border border-white cursor-pointer duration-300 hidden md:inline-block p-4 px-6 text-base text-white left-5 -top-32 tracking-wide transition uppercase mclaren-button">Play Video</button>
                      <div className="border-b border-mclaren-primary md:border-0 mb-3 pb-2 md:pb-0 text-xl md:text-3xl lg:text-5xl uppercase">MCL35M fire up</div>
                      <p className="font-light mb-4 text-sm md:text-lg">Listen to our 2021 challenger come to life</p>
                      <button className="duration-300 font-light text-sm md:text-lg text-mclaren-primary md:text-white hover:text-mclaren-primary tracking-wide transition uppercase">Read more</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden md:block absolute bottom-[10%] h-[125%] left-0 w-full asset">
                <StaticImage placeholder="blurred"
                  alt="McLaren F1"
                  className="h-[150%] object-cover w-full"
                  src="../../images/portfolio/mclaren/mclaren--banner-7.jpg"
                />
              </div>
            </div>
          </section>
          <ProjectTitle
          background="ltMclaren"
          text={{
            title: 'orange',
          }}
            content={[{
              title: 'Real-Time Updates',
              body: [
                "Throughout the course of a race weekend, TeamStream actively streams dynamic commentary, providing fans with live updates and insights as the action unfolds on the track - delivering real-time analysis and play-by-play commentary on the most crucial moments of the race.",
                "In addition to traditional media types, the TeamStream seamlessly incorporates social media content, ensuring fans can stay connected to their favorite drivers and the team's behind-the-scenes activities. Real-time updates from drivers' Twitter feeds, Instagram posts showcasing memorable moments, and Facebook updates straight from the pit lane all contribute to creating a vibrant and interactive community for McLaren enthusiasts.  Additionally, as part of the fan-centric approach, TeamStream integrates interactive elements including quizzes and competitions, giving fans the opportunity to provide valuable feedback and engage.",
                "This unique blend of commentary, images, and social media content creates an immersive and interactive experience, ensuring fans stay at the heart of the Formula 1 action throughout the entire race weekend."
              ]
            }]}
          />
      </div>
    </Layout>
  )
}

export default McLarenPage

import React, { useEffect, useRef } from "react"

function CTMGame() {
    const app = useRef(null);

    useEffect(() => {
        const cssId = 'myCss';
        if (!document.getElementById(cssId))
        {
            var link  = document.createElement('link');
            link.id   = cssId;
            link.rel  = 'stylesheet';
            link.type = 'text/css';
            link.href = '/compare-the-market/compare-the-market.css?v=001';
            link.media = 'all';
            document.body.appendChild(link);
        }

        const script = document.createElement("script");
        script.src = "/compare-the-market/bundle.js";
        script.async = true;
        script.onload = () => {
            app.current.classList.remove('hidden');
            window.pizzaApp();
        }

        document.body.appendChild(script);

        return () => {
            
        };
    }, [])

  return (
    <div ref={app} className="hidden pizza-app--wrap">
        <div id="pizza-app" data-assets-path="/compare-the-market/assets/" data-rating="0" data-active="0" className="pizza-app">
            <div className="pizza-app__steps">
                <ul className="pizza-app__steps__list"></ul>
                <div className="pizza-app__steps__title"></div>
                <button className="pizza-app__mute" aria-label="Mute sound effects">Mute</button>
                <button className="pizza-app__back steps-interation" data-reverse="" aria-label="Navigate back one step">Back</button>
                <button className="pizza-app__exit user-interation" data-exit="true" aria-label="Exit App">Exit</button>
            </div>

            <div className="pizza-app__paw--sergei--wrap"><div className="pizza-app__paw pizza-app__paw--sergei"></div></div>
            <div className="pizza-app__paw pizza-app__paw--robed"></div>
            <div data-alert-modal className="pizza-app__alert"></div>

            <section id="scene-0" className="pizza-app__scene scene-0 is-active">
                <div className="pizza-app__scene__inner bg-primary-purple">
                <div className="pizza-app__btn-group" role="group" aria-label="Pizza Menu">
                    <button
                        className="pizza-app__btn pizza-app__btn-primary user-interation uppercase"
                        data-audio="audio_pizza"
                        tabIndex={0}
                        aria-label="Choose your pizza base" data-delay-out="400" data-delay-in="800"  data-target="1">Make a Pizza and help Sergei</button>
                </div>
                </div>
            </section>
            
            <section id="scene-1" className="pizza-app__scene scene-1">
                <div className="pizza-app__scene__inner">
                <div className="pizza-app__menu__wrap">
                    <div className="pizza-app__rolling-pin"></div>
                    <ul className="pizza-app__menu">
                    <li className="pizza-app__menu__item">
                        <div className="pizza-app__menu__item__img pizza-app__menu__item__img-1">Meerkat Meals</div>
                        <div className="pizza-app__menu__item__img pizza-app__menu__item__img-2">Create your pizza</div>
                    </li>
                    <li className="pizza-app__menu__item">
                        <div className="pizza-app__menu__item__title">
                        <span>1</span> Choose a pizza base
                        </div>
                        <div className="pizza-app__menu__item__text">Choose one of the four<br /> bases in Sergei’s kitchen</div>
                    </li>
                    <li className="pizza-app__menu__item">
                        <div className="pizza-app__menu__item__title">
                        <span>2</span> Pick the toppings
                        </div>
                        <div className="pizza-app__menu__item__text">Select toppings for<br /> the pizza</div>
                    </li>
                    <li className="pizza-app__menu__item">
                        <div className="pizza-app__menu__item__title">
                        <span>3</span> Bake, bake, bake
                        </div>
                        <div className="pizza-app__menu__item__text">Make sure the pizza is<br /> cooked to perfection</div>
                    </li>
                    <li className="pizza-app__menu__item is-last">
                        <div className="pizza-app__menu__item__title pizza-app__menu__item__title--inverted">
                        <span></span> See your name
                        </div>
                        <div className="pizza-app__menu__item__text">Find out what relaxing<br /> pizza you created</div>
                    </li>
                    </ul>
                </div>
                <div className="pizza-app__btn-group" role="group" aria-label="Pizza Menu">
                    <button className="pizza-app__btn user-interation" data-audio="audio_better-get-back-to-work,audio_don-t-let-them-down" tabIndex={0} aria-label="Choose your pizza base" data-delay-out="1200" data-delay-in="2000" data-target="2">Make a pizza</button>
                </div>
                </div>
            </section>

            <section id="scene-2" className="pizza-app__scene scene-2">
                <div className="pizza-app__scene__inner">
                <div className="pizza-app__slider pizza-app__bases">
                    <div className="frame js_frame">
                    <ul className="slides js_slides"></ul>
                    </div>
                    <span className="js_prev prev"></span>
                    <span className="js_next next"></span>
                </div>
                </div>
            </section>

            <section id="scene-3" className="pizza-app__scene scene-3">
                <div className="pizza-app__scene__inner">
                <div className="pizza-app__slider pizza-app__ingredients" data-prevent-swipe="true">
                    <div className="frame js_frame">
                    <ul className="slides js_slides"></ul>
                    </div>
                    <span className="js_prev prev"></span>
                    <span className="js_next next"></span>
                </div>
                <div className="pizza-app__pizza-base pizza-app__pizza-base--update">
                    <div className="pizza-app__pizza-base__inner"></div>
                </div>
                <button className="pizza-app__undo" aria-label="Undo"></button>
                <div className="pizza-app__btn-group">
                    <button className="pizza-app__btn pizza-app__btn-link user-interation" data-delay-out="2000" data-delay-in="2000" aria-label="Bake your pizza" data-audio-force="audio_paddle-out" data-audio="audio_countdown-begins,audio_here-we-go" data-delay="2500" data-target="4">Bake</button>
                </div>
                </div>
            </section>
            
            <section id="scene-4" className="pizza-app__scene scene-4">
                <div className="pizza-app__scene__inner">
                <div className="pizza-app__btn-group">
                    <div className="pizza-app__hold-interation hold-interation" aria-label="Heat your pizza" data-delay-out="1000" data-delay-in="2000" data-hold="5000" data-delay="400" data-target="5"><span>Hold</span></div>
                </div>
                <div className="pizza-app__oven">
                    <div className="pizza-app__oven__img pizza-app__oven__img--4"></div>
                    <div className="pizza-app__oven__img pizza-app__oven__img--3"></div>
                    <div className="pizza-app__oven__img pizza-app__oven__img--2">
                    <div></div>
                    </div>
                    <div className="pizza-app__oven__img pizza-app__oven__img--1"></div>
                </div>
                </div>
            </section>

            <section id="scene-5" className="pizza-app__scene scene-5">
                <div className="pizza-app__scene__inner">
                <div className="pizza-app__pizza-box">
                    <div className="pizza-app__pizza-box--wrap">
                    <div className="pizza-app__pizza-rating">
                        <div className="pizza-app__pizza-rating__img pizza-app__pizza-rating__img-brand-logo-1">Papa John's</div>
                        <div className="pizza-app__pizza-rating__img pizza-app__pizza-rating__img-brand-logo-2">Pizza Hut</div>
                        <div className="pizza-app__pizza-rating__img pizza-app__pizza-rating__img-you-ve-created">You've created</div>
                        <div className="pizza-app__pizza-rating__name">
                        <p data-pizza-name></p>
                        </div>
                        <div className="pizza-app__pizza-rating__text">
                        <div className="pizza-app__pizza-rating__text--description">
                            <p>Now get 50% off your favourite<br /> pizza on the Meerkat app</p>
                            <div className="pizza-app__pizza-rating__img--wrap">
                            <a href="" target="_blank" aria-label="Download on the App Store" className="pizza-app__pizza-rating__img pizza-app__pizza-rating__img-app-store">Download on the App Store</a>
                            <a href="" target="_blank" aria-label="Get it on Google Play" className="pizza-app__pizza-rating__img pizza-app__pizza-rating__img-google-play">Get it on Google Play</a>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="pizza-app__pizza-base pizza-app__pizza-base--update">
                    <div className="steam"></div>
                    <div className="steam"></div>
                    <div className="steam"></div>
                    <div className="steam"></div>
                    <div data-pizza-result className="pizza-app__pizza-base__inner"></div>
                    </div>
                </div>
                <button className="pizza-app__btn user-interation" aria-label="Make another pizza" data-reset="true">Start again</button>
                </div>
            </section>
            </div>
        </div>
  )
}

export default CTMGame

import React from "react"
import { PageProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import "../../styles/tgi-fridays.css"
import ProjectHero from "../../components/ProjectHero"
import ProjectTitle from "../../components/ProjectTitle"

import "../../styles/john-lewis.css"


const JohnLewisYotiPage = (props: PageProps) => {
  gsap.config();
  gsap.registerPlugin(ScrollTrigger);

  ScrollTrigger.defaults({ ignoreMobileResize: true });

  return (
    <Layout page="work" route="/portfolio/age-verification-ai-checkout">
      <Seo 
        image={'/john-lewis/cover.jpg'}
        title="AI-Based Age Verification for Restricted Products"
        description={'Enhancing John Lewis’ Checkout with AI-Based Age Verification for Restricted Products'}
      />

      <div className="johnlewis-namespace">
        <ProjectHero
          agency={'John Lewis'}
          background="johnLewis"
          body={[
            "In the world of online retail, verifying a customer’s age is crucial when selling legally restricted products like knives, ensuring compliance with regulations and preventing unauthorised sales.",
            "For John Lewis we integrated Yoti, an AI-powered facial recognition tool, into the checkout flow. This implementation enabled a seamless and accurate age verification process that meets legal requirements while enhancing customer trust and delivering a frictionless shopping experience."
          ]}
          engagement={'2024'}
          technologies={'React, Yoti'}
          position={'Senior Product Engineer / John Lewis'}
          project={{
            label: 'JOHN LEWIS',
          }}
          title={"Project: Yoti’s Facial AI in John Lewis checkout"}
          subtitle={"Enhancing John Lewis’ Checkout with AI-Based Age Verification for Restricted Products"}
          url={{
            href: 'https://www.johnlewis.com',
            label: 'www.johnlewis.com',
          }}
        />

        <ProjectTitle 
          background="ltJohnLewis"
          content={[
            {
              title: "Why is selling knives different from selling alcohol?",
              body: [
                "While John Lewis already sells alcohol online, where customers self-declare their date of birth at checkout and age is verified upon delivery, selling knives requires stricter measures.",
                "Under the Offensive Weapons Act 2019, retailers must confirm a customer’s age both at the point of sale and upon delivery when selling bladed items. This legislation also prohibits delivery of knives to residential addresses without verified age checks, adding complexity to ensure compliance, safety, and legal accountability."
              ]
            }
          ]}
        />

        <section>
          <div className="w-full">
            <div className="mx-auto relative w-full">
              <div className="flex flex-col h-full relative w-full">
                  <div className="yoti-wrapper min-h-screen backdrop px-5 py-24">
                    <div className="yoti-container">
                      <div className="yoti-header">
                        <div>Age verification <span>(Step 1 of 2)</span></div>
                        <button>
                          <svg className="mx-auto" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10.9384 11.9991L4.96875 6.02941L6.02941 4.96875L11.9991 10.9384L17.9687 4.96875L19.0294 6.02941L13.0597 11.9991L19.0294 17.9687L17.9687 19.0294L11.9991 13.0597L6.02941 19.0294L4.96875 17.9687L10.9384 11.9991Z" fill="#141414"/>
                          </svg>
                        </button>
                      </div>
                      <div className="yoti-inner">
                        <div className="johnlewis-message message-warning">
                          <div className="mr-3">
                            <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="m16 27.25c6.2132 0 11.25-5.0368 11.25-11.25s-5.0368-11.25-11.25-11.25-11.25 5.03679-11.25 11.25c0 6.2132 5.0368 11.25 11.25 11.25zm.95-9.3491.15-7.5h-2.2l.15 7.5zm-.95 3.9995c.6904 0 1.25-.5596 1.25-1.25s-.5596-1.25-1.25-1.25-1.25.5596-1.25 1.25.5596 1.25 1.25 1.25z" fill="#ffb017" fillRule="evenodd"/></svg>
                          </div>
                          <div>
                            <strong className="block font-semibold mb-2">We&apos;ll verify your age using facial age estimation</strong>
                            <p>Please be ready to take a photo with the camera on your device.</p>
                          </div>
                        </div>
                        <div className="yoti-items">
                          <div className="mr-4 w-40">
                            <StaticImage placeholder="blurred"
                              alt="Knife"
                              className="h-full object-cover w-full"
                              src="../../images/portfolio/john-lewis/knife.png"
                            />
                          </div>
                          <div>
                            <p className="mb-2">Soft Grip Stainless Steel Santoku Knife, 17cm</p>
                            <p>Quantity: 1</p>
                          </div>
                        </div>
                        <div className="yoti-footer">
                          <button className="fake-link">Continue without restricted items</button>
                          <button className="fake-button">Verify your age</button>
                        </div>
                        <div className="yoti-logo"></div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </section>

        <ProjectTitle 
          background="ltJohnLewis"
          content={[
            {
              title: "Why Yoti’s Facial Age Estimation is the perfect fit for John Lewis",
              body: [
                "Yoti was chosen for its straightforward integration and advanced facial age estimation capabilities, eliminating the need for physical interaction or traditional ID verification. This fast, efficient solution enhances the user experience and ensures compliance. By leveraging the Yoti API, John Lewis securely redirects customers to Yoti’s trusted environment, where the entire age verification process is handled offsite.",
                "Through a simple session initiation, Yoti captures and analyses the user’s facial image using advanced AI algorithms to quickly assess age and verify liveness, eliminating the need for complex data processing on John Lewis servers. This streamlined approach ensures regulatory compliance for age-restricted products like knives, while minimising technical overhead and providing a secure, efficient, and easily implemented solution.",
              ]
            }
          ]}
        />



        
 <div data-background="ltJohnLewis" className="h-half-screen"></div>

<div data-background="johnLewis">

<ProjectTitle 
          background="johnLewis"
          text={{
            text: 'white',
            title: 'white'
          }}
          content={[
            {
              title: "Integrating Yoti’s External Verification Process in John Lewis’ checkout flow",
            },
          ]}
        />

        <div className="mx-auto">
        <section className="xl:flex items-start pb-12 md:pb-24">
              <div className="w-full xl:w-3/5">
                <div className="w-full">
                  <div className="mx-auto relative w-full">
                    <div className="flex flex-col h-full relative w-full">
                      <section className="my-24">
                        <div className="yoti-wrapper">
                          <div className="yoti-container">
                            <div className="yoti-header">
                              <div>Age verification <span>(Step 1 of 2)</span></div>
                              <button>
                                <svg className="mx-auto" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd" clipRule="evenodd" d="M10.9384 11.9991L4.96875 6.02941L6.02941 4.96875L11.9991 10.9384L17.9687 4.96875L19.0294 6.02941L13.0597 11.9991L19.0294 17.9687L17.9687 19.0294L11.9991 13.0597L6.02941 19.0294L4.96875 17.9687L10.9384 11.9991Z" fill="#141414"/>
                                </svg>
                              </button>
                            </div>
                            <div className="yoti-inner">
                              <div className="johnlewis-message message-warning">
                                <div className="mr-3">
                                  <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="m16 27.25c6.2132 0 11.25-5.0368 11.25-11.25s-5.0368-11.25-11.25-11.25-11.25 5.03679-11.25 11.25c0 6.2132 5.0368 11.25 11.25 11.25zm.95-9.3491.15-7.5h-2.2l.15 7.5zm-.95 3.9995c.6904 0 1.25-.5596 1.25-1.25s-.5596-1.25-1.25-1.25-1.25.5596-1.25 1.25.5596 1.25 1.25 1.25z" fill="#ffb017" fillRule="evenodd"/></svg>
                                </div>
                                <div>
                                  <strong className="block font-semibold mb-2">We&apos;ll verify your age using facial age estimation</strong>
                                  <p>Please be ready to take a photo with the camera on your device.</p>
                                </div>
                              </div>
                              <div className="yoti-items">
                                <div className="mr-4 w-40">
                                  <StaticImage placeholder="blurred"
                                    alt="Knife"
                                    className="h-full object-cover w-full"
                                    src="../../images/portfolio/john-lewis/knife.png"
                                  />
                                </div>
                                <div>
                                  <p className="mb-2">Soft Grip Stainless Steel Santoku Knife, 17cm</p>
                                  <p>Quantity: 1</p>
                                </div>
                              </div>
                              <div className="yoti-footer">
                                <button className="fake-link">Continue without restricted items</button>
                                <button className="fake-button">Verify your age</button>
                              </div>
                              <div className="yoti-logo"></div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
                <div className="w-full xl:w-2/5" data-speed="1.2">
                  <ProjectTitle 
                    background="johnLewis"
                    text={{
                      text: 'white',
                      title: 'white'
                    }}
                    
                    content={[
                      {
                        title: "[ Initiate Age Verification ]",
                        body: [
                          "Customer selects an age-restricted item during checkout.",
                          "System displays prompt: 'Age verification required.'",
                          "John Lewis generates a unique session URL for Yoti's secure environment.",
                        ]
                      }
                    ]}
                    width="w-full"
                  />
                </div>
            </section>

            <section className="xl:flex items-start pb-12 md:pb-24">
              <div className="w-full xl:w-3/5">
                <div className="w-full">
                  <div className="mx-auto relative w-full">
                    <div className="flex flex-col h-full relative w-full">
                      <section className="my-24">
                      <div className="w-full">
                  <div className="mx-auto relative w-full">
                    <div className="flex flex-col h-full relative w-full">
                    <div className="bg-johnLewis-primary yoti-consent">
                      <button className="yoti-back-btn">Back</button>
                      <div className="yoti-logo">
                        <svg width="65px" height="30px" viewBox="0 0 65 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
                          <defs></defs>
                          <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="dark_nav" transform="translate(-65.000000, -15.000000)">
                              <g id="yoti_logo" transform="translate(65.000000, 15.000000)">
                                <polygon id="Path" fill="#333B40" points="64.8387097 30 0 30 0 0 64.8387097 0"></polygon>
                                <polygon id="Shape" fill="#BFC2C4" points="29.0322581 2.90322581 2.90322581 2.90322581 2.90322581 27.0967742 29.0322581 27.0967742"></polygon>
                                <polygon id="Shape" fill="#2D9FFF" points="30 27.0967742 61.9354839 27.0967742 61.9354839 2.90322581 30 2.90322581"></polygon>
                                <circle id="Oval-91" fill="#333B40" cx="29.5161318" cy="15.0000028" r="7.45161272"></circle>
                                <circle id="Oval-268" fill="#FFFFFF" cx="29.5161318" cy="15.0000028" r="4.54838691"></circle>
                                <rect id="Rectangle-318" fill="#333B40" x="28.0645161" y="0.967741935" width="2.90322581" height="7.74193548"></rect>
                                <rect id="Rectangle-318" fill="#333B40" x="28.0645161" y="20.3225806" width="2.90322581" height="7.74193548"></rect>
                                <polygon id="Path" fill="#333B40" points="20.9226488 9.99999992 18.8709677 7.74193548 14.0322581 12.5806452 9.35358847 7.74193548 7.08201325 9.99999992 12.5806452 15.539856 12.5806452 22.2580645 15.483871 22.2580645 15.491904 15.539856"></polygon>
                                <polygon id="Path" fill="#333B40" points="51.2903226 10.6451613 46.4516129 10.6451613 46.4516129 22.2580645 43.5483871 22.2580645 43.5483871 10.6451613 38.7096774 10.6451613 38.7096774 7.74193548 51.2903226 7.74193548"></polygon>
                                <polygon id="Path" fill="#333B40" points="53.2258065 7.74193548 53.2258065 22.2580645 56.1290323 22.2580645 56.1290323 7.74193548"></polygon>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>

                      <div className="yoti-consent-inner">
                        <div className="yoti-consent-header">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" data-qa="icon" aria-hidden="true"><defs><path id="yoti-svg-zaYh6" d="M19.207 11.707l-1.415-1.415-5.292 5.293-2.293-2.293-1.415 1.415 3.708 3.707 6.707-6.707zM8 7c-.551 0-1 .449-1 1v12c0 .551.449 1 1 1h12c.551 0 1-.449 1-1V8c0-.551-.449-1-1-1H8zm12 16H8c-1.654 0-3-1.346-3-3V8c0-1.654 1.346-3 3-3h12c1.654 0 3 1.346 3 3v12c0 1.654-1.346 3-3 3z"></path></defs><g fill="none" fillRule="evenodd"><mask id="yoti-svg-zgDPY" fill="#fff"><use xlinkHref="#yoti-svg-zaYh6"></use></mask><use fill="currentColor" xlinkHref="#yoti-svg-zaYh6"></use></g></svg>
                          Your consent is needed
                        </div>
                        <div className="yoti-consent-body">
                          <ul>
                            <li>
                              <div>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd" clipRule="evenodd" d="M17.3031 17.3014H11.7799V14.9015C13.5961 14.5389 14.9695 12.9325 14.9695 11.0114C14.9695 9.08992 13.5961 7.48353 11.7799 7.12092V4.69896H17.3031V17.3014ZM4.70064 4.69896H10.2238V7.12092C8.40764 7.48353 7.03421 9.08992 7.03421 11.0114C7.03421 12.9325 8.40764 14.5389 10.2238 14.9015V17.3014H4.70064V4.69896ZM11.0017 13.4227C9.67185 13.4227 8.59032 12.3408 8.59032 11.0114C8.59032 9.68157 9.67185 8.59964 11.0017 8.59964C12.3315 8.59964 13.4134 9.68157 13.4134 11.0114C13.4134 12.3408 12.3315 13.4227 11.0017 13.4227ZM3.14453 18.8571H18.8588V3.14285H3.14453V18.8571Z" fill="#333B40"/>
                                </svg>
                              </div>
                              <p>Our service partner, Yoti, needs to collect an image of your face - under some laws this may be biometric data.</p>
                            </li>
                            <li>
                              <div>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M5.68847 5.90463L4.59868 4.81446C6.25025 3.39467 8.28683 2.55631 10.4589 2.39171V3.92856H12.0304V2.39171C14.2021 2.55631 16.2386 3.39467 17.8906 4.81446L16.8004 5.90463L17.9114 7.01563L20.1338 4.79324L19.5779 4.23774C17.3524 2.01181 14.3926 0.785706 11.2446 0.785706C8.09629 0.785706 5.1365 2.01181 2.91097 4.23774L2.35547 4.79324L4.57747 7.01563L5.68847 5.90463Z" fill="#333B40"/>
                                  <path d="M10.5535 13.7405C10.5535 13.9573 10.7291 14.1333 10.9464 14.1333H11.5427C11.6555 14.1333 11.7624 14.085 11.8374 14.0006C11.9116 13.9161 11.9466 13.8037 11.9325 13.6918L11.6343 11.3063C11.6076 11.0911 11.4104 10.9382 11.1959 10.9653C10.9806 10.9921 10.8277 11.1885 10.8549 11.4038L11.0976 13.3476H10.9464C10.7291 13.3476 10.5535 13.5236 10.5535 13.7405Z" fill="#333B40"/>
                                  <path d="M8.85879 11.0568C9.18839 11.0568 9.45554 11.324 9.45554 11.6532C9.45554 11.9824 9.18839 12.2495 8.85879 12.2495C8.52996 12.2495 8.26282 11.9824 8.26282 11.6532C8.26282 11.324 8.52996 11.0568 8.85879 11.0568Z" fill="#333B40"/>
                                  <path d="M14.2264 11.6532C14.2264 11.9824 13.9592 12.2495 13.63 12.2495C13.3004 12.2495 13.0333 11.9824 13.0333 11.6532C13.0333 11.324 13.3004 11.0568 13.63 11.0568C13.9592 11.0568 14.2264 11.324 14.2264 11.6532Z" fill="#333B40"/>
                                  <path d="M12.782 15.3959C12.9918 15.3397 13.207 15.463 13.264 15.6728C13.3206 15.8822 13.1964 16.0979 12.987 16.1545C12.5561 16.2708 11.9208 16.3375 11.2443 16.3375C10.5682 16.3375 9.93298 16.2708 9.50201 16.1545C9.29262 16.0979 9.16848 15.8822 9.22505 15.6728C9.28162 15.463 9.4969 15.3397 9.70669 15.3959C10.4295 15.5911 12.0595 15.5911 12.782 15.3959Z" fill="#333B40"/>
                                  <path d="M14.1036 6.07667C14.9785 6.44595 15.7642 6.97434 16.4383 7.64731C17.1129 8.32067 17.642 9.10481 18.0125 9.97774C18.3955 10.8817 18.5904 11.8422 18.5904 12.8326C18.5904 13.823 18.3955 14.7836 18.0121 15.6879C17.642 16.5608 17.1129 17.345 16.4383 18.0183C15.8824 18.5727 15.323 18.9997 14.731 19.3207L17.5065 19.95L17.1588 21.4826L14.2635 20.8261C13.5626 20.6548 13.0818 20.0431 13.0818 19.3352C13.0818 18.7632 13.3976 18.2438 13.906 17.9798C14.3982 17.7245 14.85 17.3831 15.3285 16.9058C15.8585 16.3766 16.2749 15.7606 16.5652 15.0747C16.8665 14.3652 17.019 13.6113 17.019 12.8326C17.019 12.0544 16.8665 11.3001 16.5656 10.5914C16.2749 9.90506 15.8585 9.28906 15.3281 8.75949C14.7978 8.23031 14.1802 7.81467 13.4923 7.52434C12.0698 6.92367 10.4198 6.92367 8.99644 7.52434C8.30894 7.81467 7.69098 8.23031 7.16101 8.75949C6.63026 9.28906 6.21423 9.90506 5.92351 10.591C5.62259 11.3001 5.47016 12.0544 5.47016 12.8326C5.47016 13.6113 5.62259 14.3652 5.92312 15.0743C6.21423 15.7606 6.63026 16.3766 7.16101 16.9062C7.63912 17.3831 8.09091 17.7245 8.58316 17.9798C9.09151 18.2438 9.40737 18.7632 9.40737 19.3352C9.40737 20.0431 8.92651 20.6548 8.23862 20.823L5.3303 21.4826L4.98262 19.95L7.75776 19.3207C7.16573 18.9997 6.60669 18.5727 6.05119 18.0183C5.37626 17.345 4.84669 16.5608 4.47662 15.6875C4.09319 14.7836 3.89873 13.823 3.89873 12.8326C3.89873 11.8422 4.09319 10.8817 4.47702 9.97774C4.84669 9.10481 5.37626 8.32067 6.0508 7.64731C6.72455 6.97434 7.51026 6.44595 8.38516 6.07667C10.1974 5.31177 12.2917 5.31177 14.1036 6.07667Z" fill="#333B40"/>
                                </svg>
                              </div>
                              <p>Yoti will use your image to estimate your age and not for any other purpose.</p>
                            </li>
                            <li>
                              <div>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd" clipRule="evenodd" d="M16.4983 5.49999H5.49833V18.0714C5.49833 18.5047 5.85072 18.8571 6.28404 18.8571H15.7126C16.1459 18.8571 16.4983 18.5047 16.4983 18.0714V5.49999ZM9.4269 3.92856H12.5698C12.5698 3.06192 11.865 2.35713 10.9983 2.35713C10.1317 2.35713 9.4269 3.06192 9.4269 3.92856ZM19.6412 5.49999H18.0698V18.0714C18.0698 19.371 17.0126 20.4286 15.7126 20.4286H6.28404C4.98408 20.4286 3.9269 19.371 3.9269 18.0714V5.49999H2.35547V3.92856H7.85547C7.85547 2.19567 9.26543 0.785706 10.9983 0.785706C12.7312 0.785706 14.1412 2.19567 14.1412 3.92856H19.6412V5.49999ZM12.9626 15.7143C13.1795 15.7143 13.3555 15.5383 13.3555 15.3214V9.03571C13.3555 8.81885 13.1795 8.64285 12.9626 8.64285C12.7458 8.64285 12.5698 8.81885 12.5698 9.03571V15.3214C12.5698 15.5383 12.7458 15.7143 12.9626 15.7143ZM8.64118 15.3214V9.03571C8.64118 8.81885 8.81718 8.64285 9.03404 8.64285C9.2509 8.64285 9.4269 8.81885 9.4269 9.03571V15.3214C9.4269 15.5383 9.2509 15.7143 9.03404 15.7143C8.81718 15.7143 8.64118 15.5383 8.64118 15.3214Z" fill="#333B40"/>
                                </svg>
                              </div>
                              <p>Yoti deletes your image immediately after the estimation is done.</p>
                            </li>
                          </ul>
                          <p className="mb-8">More information can be found at Yoti’s Privacy Notice including Biometrics Policy</p>
                          <div className="bg-[#229dff33] flex gap-2 mb-4 p-4 rounded-md">
                            <div className="yoti-checkbox relative">
                              <input id="checkbox_1" type="checkbox" value="true" defaultChecked />
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 11" aria-hidden="true"><path fill="none" fillRule="evenodd" stroke="#1b9f4a" strokeLinecap="square" strokeWidth="2.5" d="M7 10.438L10.449 13.887 15.851 7.013" transform="translate(-5 -5)"></path></svg>
                            </div>
                            <label htmlFor="checkbox_1">
                              I consent to Yoti’s collection and use of an image of my face to estimate my age.
                            </label>
                          </div>
                          <div className="bg-[#229dff33] flex gap-2 mb-8 p-4 rounded-md">
                            <div className="yoti-checkbox relative">
                              <input id="checkbox_2" type="checkbox" value="true" />
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 11" aria-hidden="true"><path fill="none" fillRule="evenodd" stroke="#1b9f4a" strokeLinecap="square" strokeWidth="2.5" d="M7 10.438L10.449 13.887 15.851 7.013" transform="translate(-5 -5)"></path></svg>
                            </div>
                            <label htmlFor="checkbox_2">
                              I have read and acknowledge Yoti’s Privacy Notice including Biometrics Policy.
                            </label>
                          </div>
                          <p className="text-center mb-4">You&apos;ll be asked to enable camera access</p>
                          <button className="fake-button rounded-md w-full">Continue</button>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
                <div className="w-full xl:w-2/5" data-speed="1.2">
                  <ProjectTitle 
                    background="johnLewis"
                    text={{
                      text: 'white',
                      title: 'white'
                    }}
                    
                    content={[
                      {
                        title: "[ Redirect to Yoti ]",
                        body: [
                          "User is redirected to Yoti's portal.",
                          "URL contains encrypted parameters to link verification to purchase.",
                        ]
                      }
                    ]}
                    width="w-full"
                  />
                </div>
            </section>

            <section className="xl:flex items-start pb-12 md:pb-24">
              <div className="w-full xl:w-3/5">
                <div className="w-full">
                  <div className="mx-auto relative w-full">
                    <div className="flex flex-col h-full relative w-full">
                    <section>
  <div className="w-full">
    <div className="mx-auto relative w-full">
      <div className="flex flex-col relative w-full">
      <div className="yoti-consent">
        <button className="yoti-back-btn">Back</button>
        <div className="yoti-logo">
          <svg width="65px" height="30px" viewBox="0 0 65 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs></defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="dark_nav" transform="translate(-65.000000, -15.000000)">
                <g id="yoti_logo" transform="translate(65.000000, 15.000000)">
                  <polygon id="Path" fill="#333B40" points="64.8387097 30 0 30 0 0 64.8387097 0"></polygon>
                  <polygon id="Shape" fill="#BFC2C4" points="29.0322581 2.90322581 2.90322581 2.90322581 2.90322581 27.0967742 29.0322581 27.0967742"></polygon>
                  <polygon id="Shape" fill="#2D9FFF" points="30 27.0967742 61.9354839 27.0967742 61.9354839 2.90322581 30 2.90322581"></polygon>
                  <circle id="Oval-91" fill="#333B40" cx="29.5161318" cy="15.0000028" r="7.45161272"></circle>
                  <circle id="Oval-268" fill="#FFFFFF" cx="29.5161318" cy="15.0000028" r="4.54838691"></circle>
                  <rect id="Rectangle-318" fill="#333B40" x="28.0645161" y="0.967741935" width="2.90322581" height="7.74193548"></rect>
                  <rect id="Rectangle-318" fill="#333B40" x="28.0645161" y="20.3225806" width="2.90322581" height="7.74193548"></rect>
                  <polygon id="Path" fill="#333B40" points="20.9226488 9.99999992 18.8709677 7.74193548 14.0322581 12.5806452 9.35358847 7.74193548 7.08201325 9.99999992 12.5806452 15.539856 12.5806452 22.2580645 15.483871 22.2580645 15.491904 15.539856"></polygon>
                  <polygon id="Path" fill="#333B40" points="51.2903226 10.6451613 46.4516129 10.6451613 46.4516129 22.2580645 43.5483871 22.2580645 43.5483871 10.6451613 38.7096774 10.6451613 38.7096774 7.74193548 51.2903226 7.74193548"></polygon>
                  <polygon id="Path" fill="#333B40" points="53.2258065 7.74193548 53.2258065 22.2580645 56.1290323 22.2580645 56.1290323 7.74193548"></polygon>
                </g>
              </g>
            </g>
          </svg>
        </div>

        <div className="yoti-consent-camera">
          <div className="yoti-camera-box">
            <img
              alt="Yoti"
              className="absolute h-full object-cover w-full z-0"
              src="/john-lewis/hare-and-bear.gif"
            />
            <StaticImage placeholder="blurred"
              alt="Yoti"
              className="absolute h-full object-cover w-full z-10"
              src="../../images/portfolio/john-lewis/yoti-overlay.png"
            />
            <span className="yoti-hold-still z-20">Please hold still</span>
            <button className="yoti-help-btn z-20">Help</button>
          </div>
          <ul className="yoti-camera-instructions">
          <li>
              <StaticImage placeholder="blurred"
                alt="Sun"
                className="h-full object-cover"
                src="../../images/portfolio/john-lewis/yoti-sun.png"
              />
              <p>Find a light area</p>
            </li>
            <li>
              <StaticImage placeholder="blurred"
                alt="Hat"
                className="h-full object-cover"
                src="../../images/portfolio/john-lewis/yoti-hat.png"
              />
              <p>Remove any headwear</p>
            </li>
            <li>
              <StaticImage placeholder="blurred"
                alt="Glasses"
                className="h-full object-cover"
                src="../../images/portfolio/john-lewis/yoti-glasses.png"
              />
              <p>Keep your glasses on</p>
            </li>
          </ul>
        </div>
      </div>
      </div>
    </div>
  </div>
 </section>
                    </div>
                  </div>
                </div>
              </div>
                <div className="w-full xl:w-2/5" data-speed="1.2">
                  <ProjectTitle 
                    background="johnLewis"
                    text={{
                      text: 'white',
                      title: 'white'
                    }}
                    
                    content={[
                      {
                        title: "[ Age Verification at Yoti ]",
                        body: [
                          "User completes age verification in Yoti’s environment.",
                        ]
                      }
                    ]}
                    width="w-full"
                  />
                </div>
            </section>

            <section className="xl:flex items-start pb-12 md:pb-24">
              <div className="w-full xl:w-3/5">
                <div className="w-full">
                  <div className="mx-auto relative w-full">
                    <div className="flex flex-col h-full relative w-full">
                    <section className="bg-johnLewis-primary my-24">
                        <div className="yoti-loading-wrapper">
                          <div className="loading-spinner" />
                          <p>please wait while we verify your age, this could take up to 30 seconds</p>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
                <div className="w-full xl:w-2/5" data-speed="1.2">
                  <ProjectTitle 
                    background="johnLewis"
                    text={{
                      text: 'white',
                      title: 'white'
                    }}
                    
                    content={[
                      {
                        title: "[ API Callback from Yoti ]",
                        body: [
                          "Yoti sends a secure JSON response to John Lewis’s backend with estimated age.",
                          "The backend checks if the customer meets the required age threshold. ",
                        ]
                      }
                    ]}
                    width="w-full"
                  />
                </div>
            </section>

            <section className="xl:flex items-start pb-12 md:pb-24">
              <div className="w-full xl:w-3/5">
                <div className="w-full">
                  <div className="mx-auto relative w-full">
                    <div className="flex flex-col h-full relative w-full">
                    <section className="my-24">
                        <div className="yoti-wrapper">
                          <div className="yoti-container">
                            <div className="yoti-header">
                              <div>Age verification complete</div>
                              <button>
                                <svg className="mx-auto" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd" clipRule="evenodd" d="M10.9384 11.9991L4.96875 6.02941L6.02941 4.96875L11.9991 10.9384L17.9687 4.96875L19.0294 6.02941L13.0597 11.9991L19.0294 17.9687L17.9687 19.0294L11.9991 13.0597L6.02941 19.0294L4.96875 17.9687L10.9384 11.9991Z" fill="#141414"/>
                                </svg>
                              </button>
                            </div>
                            <div className="yoti-inner">
                              <div className="yoti-confirmation">
                                <div>
                                  <svg className="mr-2" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.1776 11.918L13.2959 18.7996L11.5006 17.0043L10.0863 18.4185L13.2959 21.628L21.5918 13.3322L20.1776 11.918Z" fill="#141414"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M27.25 16C27.25 22.2132 22.2132 27.25 16 27.25C9.7868 27.25 4.75 22.2132 4.75 16C4.75 9.7868 9.7868 4.75 16 4.75C22.2132 4.75 27.25 9.7868 27.25 16ZM25.25 16C25.25 21.1086 21.1086 25.25 16 25.25C10.8914 25.25 6.75 21.1086 6.75 16C6.75 10.8914 10.8914 6.75 16 6.75C21.1086 6.75 25.25 10.8914 25.25 16Z" fill="#141414"/>
                                  </svg>
                                </div>
                                <p>We have confirmed you meet the required age</p>
                              </div>
                              <div className="yoti-footer">
                                <button className="fake-button">Continue to checkout</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
                <div className="w-full xl:w-2/5" data-speed="1.2">
                  <ProjectTitle 
                    background="johnLewis"
                    text={{
                      text: 'white',
                      title: 'white'
                    }}
                    
                    content={[
                      {
                        title: "[ Process Results - Proceed with Checkout ]",
                        body: [
                          "If successful, user continues with checkout.",
                        ]
                      }
                    ]}
                    width="w-full"
                  />
                </div>
            </section>

            <section className="xl:flex items-start pb-12 md:pb-24">
              <div className="w-full xl:w-3/5">
                <div className="w-full">
                  <div className="mx-auto relative w-full">
                    <div className="flex flex-col h-full relative w-full">
                    <section className="my-24">
                    <div className="yoti-wrapper">
                          <div className="yoti-container">
                            <div className="yoti-header">
                              <div>Age verification <span>(Step 1 of 2)</span></div>
                              <button>
                                <svg className="mx-auto" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd" clipRule="evenodd" d="M10.9384 11.9991L4.96875 6.02941L6.02941 4.96875L11.9991 10.9384L17.9687 4.96875L19.0294 6.02941L13.0597 11.9991L19.0294 17.9687L17.9687 19.0294L11.9991 13.0597L6.02941 19.0294L4.96875 17.9687L10.9384 11.9991Z" fill="#141414"/>
                                </svg>
                              </button>
                            </div>
                            <div className="yoti-inner">
                              <div className="johnlewis-message message-error">
                                <div className="mr-3">
                                  <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="m15.5 4 12.5 22.5h-25zm1.0998 8.7003h-2.2l.15 6.25h1.9zm.1502 8.75c0 .6903-.5596 1.25-1.25 1.25s-1.25-.5597-1.25-1.25c0-.6904.5596-1.25 1.25-1.25s1.25.5596 1.25 1.25z" fill="#cc1426" fillRule="evenodd"/></svg>
                                </div>
                                <div>
                                  <strong className="block font-semibold mb-2">We couldn&apos;t verify your age</strong>
                                  <p className="mb-2">Sorry, you won&apos;t be able to buy these items as we couldn&apos;t verify you&apos;re over 18.&nbsp;You can continue without the age-restricted items.</p>
                                  <p><button className="fake-link fake-link-sm">Help with age verification</button></p>
                                </div>
                              </div>
                              <div className="yoti-items">
                                <div className="mr-4 w-40">
                                  <StaticImage placeholder="blurred"
                                    alt="Knife"
                                    className="h-full object-cover w-full"
                                    src="../../images/portfolio/john-lewis/knife.png"
                                  />
                                </div>
                                <div>
                                  <p className="mb-2">Soft Grip Stainless Steel Santoku Knife, 17cm</p>
                                  <p>Quantity: 1</p>
                                </div>
                              </div>
                              <div className="yoti-footer">
                                <button className="fake-link">Return to basket</button>
                                <button className="fake-button">Continue without restricted items</button>
                              </div>
                              <div className="yoti-logo"></div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
                <div className="w-full xl:w-2/5" data-speed="1.2">
                  <ProjectTitle 
                    background="johnLewis"
                    text={{
                      text: 'white',
                      title: 'white'
                    }}
                    
                    content={[
                      {
                        title: "[ Process Results - Display Error Message ]",
                        body: [
                          "If unsuccessful, prompt user with appropriate message.",
                        ]
                      }
                    ]}
                    width="w-full"
                  />
                </div>
            </section>
          </div>


<ProjectTitle 
          background="johnLewis"
          text={{
            text: 'white',
            title: 'white'
          }}
          content={[
            {
              subtitle: "Resuming the Checkout Flow:",
              items: [{
                body: [
                  "<em>Successful Verification:</em> The user is redirected back to the checkout page and can proceed with their purchase.",
                ]
              }, {
                body: [
                  "<em>Failed Verification:</em> If the verification fails, the user receives a clear prompt explaining why the purchase cannot continue, maintaining transparency while ensuring compliance.",
                ]
              }]
            },
          ]}
        />
        
        <ProjectTitle 
          background="johnLewis"
          text={{
            text: 'white',
            title: 'white'
          }}
          content={[
            {
              title: "Addressing Technical and UX Challenges",
              body: [
                "Integrating an external age verification process introduced unique technical and user experience challenges. Here’s how we tackled each to ensure a smooth, frustration-free checkout flow:",
              ]
            },
            {
              subtitle: "1. Ensuring a Cohesive User Flow",
              body: [
                "To prevent disruption during the checkout process, we implemented an efficient redirection from John Lewis to Yoti and back again:",
              ],
            }, 
            {
              heading: "Clear Communication:",
              body: [
                "Customers were informed upfront about why age verification was required, helping set expectations.",
              ]
            }, {
              heading: "Automatic Return:",
              body: [
                "Upon successful verification, users were automatically redirected to the exact stage of checkout they left off, ensuring continuity and minimising friction.",
              ]
            },
          ]}
        />

        <div className="mx-auto">

            <ProjectTitle 
              background="johnLewis"
              text={{
                text: 'white',
                title: 'white'
              }}
              content={[
                {
                  subtitle: "2. Managing Sessions and State Continuity",
                  body: [
                    "Redirecting to an external environment can risk session loss, so we developed mechanisms to retain user progress:",
                  ],
                }, 
                {
                  heading: "State Preservation:",
                  body: [
                    "Key session and cart data were securely tracked so users could resume where they left off without re-entering information.",
                  ]
                }, {
                  heading: "Graceful Recovery: ",
                  body: [
                    "If users navigated away during verification, their session could be restored when they returned, reducing frustration and abandonment.",
                  ]
                },
              ]}
            />


            <ProjectTitle 
              background="johnLewis"
              text={{
                text: 'white',
                title: 'white'
              }}
              content={[
                {
                  subtitle: "3. Enhancing User Guidance in the Yoti Environment",
                  body: [
                    "To support users unfamiliar with facial verification, we collaborated with Yoti to optimise the experience:",
                  ],
                }, 
                {
                  heading: "Helpful Prompts:",
                    body: [
                      "Yoti’s interface was enhanced with clear instructions to guide users through the verification process.",
                    ]
                  }, {
                    heading: "Cohesive Branding: ",
                    body: [
                      "Subtle styling adjustments aligned the Yoti environment with John Lewis’ brand, creating a familiar look and feel.",
                    ]
                  },  {
                    heading: "Support Availability:",
                    body: [
                      "Clear help links on the John Lewis checkout page provided quick assistance for users encountering difficulties.",
                    ]
                },
              ]}
            />



            <ProjectTitle 
              background="johnLewis"
              text={{
                text: 'white',
                title: 'white'
              }}
              content={[
                {
                  subtitle: "4. Robust Error Handling and Service Unavailability",
                  body: [
                    "While Yoti’s service is highly reliable, we prepared for potential outages to maintain user confidence and trust:"
                  ],
                },
              ]}
            />


            <section className="xl:flex items-start pb-12 md:pb-24">
              <div className="w-full xl:w-3/5">
                <div className="w-full">
                  <div className="mx-auto relative w-full">
                    <div className="flex flex-col h-full relative w-full">
                      <section className="my-24">
                        <div className="yoti-wrapper">
                          <div className="yoti-container">
                            <div className="yoti-header">
                              <div>Age verification <span>(Step 1 of 2)</span></div>
                              <button>
                                <svg className="mx-auto" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd" clipRule="evenodd" d="M10.9384 11.9991L4.96875 6.02941L6.02941 4.96875L11.9991 10.9384L17.9687 4.96875L19.0294 6.02941L13.0597 11.9991L19.0294 17.9687L17.9687 19.0294L11.9991 13.0597L6.02941 19.0294L4.96875 17.9687L10.9384 11.9991Z" fill="#141414"/>
                                </svg>
                              </button>
                            </div>
                            <div className="yoti-inner">
                              <div className="johnlewis-message message-error">
                                <div className="mr-3">
                                  <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="m15.5 4 12.5 22.5h-25zm1.0998 8.7003h-2.2l.15 6.25h1.9zm.1502 8.75c0 .6903-.5596 1.25-1.25 1.25s-1.25-.5597-1.25-1.25c0-.6904.5596-1.25 1.25-1.25s1.25.5596 1.25 1.25z" fill="#cc1426" fillRule="evenodd"/></svg>
                                </div>
                                <div>
                                  <strong className="block font-semibold mb-2">We couldn&apos;t verify your age</strong>
                                  <p className="mb-2">Sorry, you won&apos;t be able to buy these items as we couldn&apos;t verify you&apos;re over 18.&nbsp;You can continue without the age-restricted items.</p>
                                  <p><button className="fake-link fake-link-sm">Help with age verification</button></p>
                                </div>
                              </div>
                              <div className="yoti-items">
                                <div className="mr-4 w-40">
                                  <StaticImage placeholder="blurred"
                                    alt="Knife"
                                    className="h-full object-cover w-full"
                                    src="../../images/portfolio/john-lewis/knife.png"
                                  />
                                </div>
                                <div>
                                  <p className="mb-2">Soft Grip Stainless Steel Santoku Knife, 17cm</p>
                                  <p>Quantity: 1</p>
                                </div>
                              </div>
                              <div className="yoti-footer">
                                <button className="fake-link">Return to basket</button>
                                <button className="fake-button">Continue without restricted items</button>
                              </div>
                              <div className="yoti-logo"></div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
                <div className="w-full xl:w-2/5" data-speed="1.2">
                <ProjectTitle 
                    background="johnLewis"
                    text={{
                      text: 'white',
                      title: 'white'
                    }}
                    content={[
                      {
                        subtitle: "Friendly Messaging:",
                        body: [
                          "If the service was unavailable, users received clear, jargon-free messages explaining the temporary issue. To maintain a smooth experience, we provided additional options:"
                        ],
                      },
                      
                    ]}
                    width="w-full"
                  />
                </div>
            </section>

            <section className="xl:flex items-start pb-12 md:pb-24">
              <div className="w-full xl:w-3/5">
                <div className="w-full">
                  <div className="mx-auto relative w-full">
                    <div className="flex flex-col h-full relative w-full">
                    <section className="my-24">
                        <div className="yoti-wrapper mb-8">
                          <div className="yoti-container">
                            <div className="yoti-inner">
                              <div className="johnlewis-message message-error">
                                <div className="mr-3">
                                  <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="m15.5 4 12.5 22.5h-25zm1.0998 8.7003h-2.2l.15 6.25h1.9zm.1502 8.75c0 .6903-.5596 1.25-1.25 1.25s-1.25-.5597-1.25-1.25c0-.6904.5596-1.25 1.25-1.25s1.25.5596 1.25 1.25z" fill="#cc1426" fillRule="evenodd"/></svg>
                                </div>
                                <div>
                                  <strong className="block font-semibold mb-2">Age verification is temporarily unavailable</strong>
                                  <p>Sorry, there&apos;s a technical problem so we can&apos;t verify your age at the moment. Please check back soon, or save your items for later to continue.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="yoti-wrapper mb-8">
                          <div className="yoti-container">
                            <div className="yoti-inner">
                              <div className="johnlewis-message message-error">
                                <div className="mr-3">
                                  <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="m15.5 4 12.5 22.5h-25zm1.0998 8.7003h-2.2l.15 6.25h1.9zm.1502 8.75c0 .6903-.5596 1.25-1.25 1.25s-1.25-.5597-1.25-1.25c0-.6904.5596-1.25 1.25-1.25s1.25.5596 1.25 1.25z" fill="#cc1426" fillRule="evenodd"/></svg>
                                </div>
                                <div>
                                  <strong className="block font-semibold mb-2">Age verification is temporarily unavailable</strong>
                                  <p>Sorry, there&apos;s a technical problem so we can&apos;t verify your age at the moment. Please check back soon, return to your basket.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="yoti-wrapper">
                          <div className="yoti-container">
                            <div className="yoti-inner">
                              <div className="johnlewis-message message-error">
                                <div className="mr-3">
                                  <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="m15.5 4 12.5 22.5h-25zm1.0998 8.7003h-2.2l.15 6.25h1.9zm.1502 8.75c0 .6903-.5596 1.25-1.25 1.25s-1.25-.5597-1.25-1.25c0-.6904.5596-1.25 1.25-1.25s1.25.5596 1.25 1.25z" fill="#cc1426" fillRule="evenodd"/></svg>
                                </div>
                                <div>
                                  <strong className="block font-semibold mb-2">We couldn&apos;t verify your age</strong>
                                  <p className="mb-2">Sorry, you won&apos;t be able to buy these items as we couldn&apos;t verify you&apos;re over 18.{` `}You can continue without the age-restricted items.</p>
                                  <p><button className="fake-link fake-link-sm">Help with age verification</button></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
                <div className="w-full xl:w-2/5" data-speed="1.2">
                  <ProjectTitle 
                    background="johnLewis"
                    text={{
                      text: 'white',
                      title: 'white'
                    }}
                    content={[
                      {
                        heading: "Save Items for Later:",
                        body: [
                          "If there were other items in the basket, users could save the restricted product for a future purchase."
                        ],
                      },{
                        heading: "Return to Basket:",
                        body: [
                          "If the basket contained only the restricted item, users could quickly return to their basket to modify their purchase."
                        ],
                      },{
                        heading: "Further Guidance:",
                        body: [
                          "Links to additional support and guidance were provided to help users understand the next steps or resolve any concerns."
                        ],
                      },
                      
                    ]}
                    width="w-full"
                  />
                </div>
            </section>

            <section className="xl:flex items-start pb-12 md:pb-24">
              <div className="w-full xl:w-3/5">
                <div className="w-full">
                  <div className="mx-auto relative w-full">
                    <div className="flex flex-col h-full relative w-full">
                      <section className="my-24">
                        <div className="yoti-wrapper">
                          <div className="yoti-container">
                            <div className="yoti-inner">
                            <div className="yoti-items">
                                <div className="mr-4 w-40">
                                  <StaticImage placeholder="blurred"
                                    alt="Knife"
                                    className="h-full object-cover w-full"
                                    src="../../images/portfolio/john-lewis/knife.png"
                                  />
                                </div>
                                <div>
                                  <p className="mb-2">Soft Grip Stainless Steel Santoku Knife, 17cm</p>
                                  <p>Quantity: 1</p>
                                </div>
                              </div>
                              <div className="yoti-items">
                                <div className="mr-4 w-40">
                                  <StaticImage placeholder="blurred"
                                    alt="Knife"
                                    className="h-full object-cover w-full"
                                    src="../../images/portfolio/john-lewis/pans.png"
                                  />
                                </div>
                                <div>
                                  <p className="mb-2">Le Creuset 3-Ply Stainless Steel Saucepans and Frying Pan Set, 4 Pieces</p>
                                  <p>Quantity: 1</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
                <div className="w-full xl:w-2/5" data-speed="1.2">
                  <ProjectTitle 
                    background="johnLewis"
                    text={{
                      text: 'white',
                      title: 'white'
                    }}
                    content={[
                      {
                        subtitle: "Data Integrity:",
                        body: [
                          "Cart and checkout data were preserved, ensuring users wouldn’t lose progress or need to re-enter information upon returning to complete their purchase."
                        ],
                      },
                    ]}
                    width="w-full"
                  />
                </div>
            </section>

            <section className="xl:flex items-start pb-12 md:pb-24">
              <div className="w-full xl:w-3/5">
                <div className="w-full">
                  <div className="mx-auto relative w-full">
                    <div className="flex flex-col h-full relative w-full">
                      <section className="my-24">
                        <div className="yoti-wrapper mb-8">
                          <div className="yoti-container">
                            <div className="yoti-inner">
                              <div className="yoti-footer">
                                <button className="fake-link">Return to basket</button>
                                <button className="fake-button">Continue without restricted items</button>
                              </div>
                              <div className="yoti-logo"></div>
                            </div>
                          </div>
                        </div>
                        <div className="yoti-wrapper">
                          <div className="yoti-container">
                            <div className="yoti-inner">
                              <div className="yoti-footer">
                                <button className="fake-button">Return to basket</button>
                              </div>
                              <div className="yoti-logo"></div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
                <div className="w-full xl:w-2/5" data-speed="1.2">
                  <ProjectTitle 
                    background="johnLewis"
                    text={{
                      text: 'white',
                      title: 'white'
                    }}
                    content={[
                      {
                        subtitle: "Continue Without Restricted Items:",
                        body: [
                          "Customers were given flexible options to maintain a smooth experience:",
                        ],
                      }, {
                        body: [
                          "<em>Proceed with Other Items:</em> Users could continue to purchase the non-restricted items in their cart, excluding the age-restricted product.",
                        ],
                      }, {
                        body: [
                          "<em>Quick Return to Basket:</em> If the basket contained only the restricted item, users could quickly return to their basket to modify their purchase or add alternative items.",
                        ],
                      },
                    ]}
                    width="w-full"
                  />
                </div>
            </section>
          </div>
        </div>
        
        <div data-background="ltJohnLewis" className="h-half-screen"></div>
        <ProjectTitle 
          background="ltJohnLewis"
          
          content={[
            {
              title: "Achieving compliance and enhancing customer experience",
              body: [
                "By adopting Yoti’s facial age estimation, John Lewis ensures full compliance with legal requirements for selling restricted products online. This integration guarantees that age-restricted items, like knives, are sold responsibly, while also enhancing the checkout experience with a swift and secure verification process. Moreover, this approach maintains technical stability and upholds the trusted, user-centric standards that John Lewis is known for.",
                "The result is a seamless, user-friendly journey that balances regulatory compliance with customer trust and convenience, reinforcing John Lewis’ commitment to quality and satisfaction.",
              ]
            }
          ]}
        />
      </div>
      <div data-background="ltJohnLewis" className="h-half-screen"></div>
    </Layout>
  )
}

export default JohnLewisYotiPage

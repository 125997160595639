import React, { useEffect } from "react"
import { PageProps } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { SplitText } from "gsap/SplitText"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"
import { StaticImage } from "gatsby-plugin-image"

import "../../styles/tgi-fridays.css"
import ProjectHero from "../../components/ProjectHero"
import ProjectTitle from "../../components/ProjectTitle"

const TGIFridaysPage = (props: PageProps) => {
  gsap.config();
  gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger, SplitText);

  ScrollTrigger.defaults({ ignoreMobileResize: true });

  useEffect(() => {
    
    gsapFunction();

    function gsapFunction() {
      const images = gsap.utils.toArray('.tgi-fridays--img');
      const inviews = gsap.utils.toArray('.inview');

      inviews.forEach((inview) => {
        gsap.from(inview, {
          scrollTrigger: {
            start: 'top bottom',
            end: 'bottom top',
            trigger: inview,
            toggleClass: 'enable'
          }
        });
      });
    
      images.forEach((image, i) => {
        const parent = image.closest('div');
        gsap.fromTo(
          image,
          { 
            scale: 1.2 
          },
          {
            scale: 1,
            ease: "none",
            force3D: true,
            scrollTrigger: {
              trigger: parent,
              start: 'top center',
              end: 'bottom center',
              pinType: "transform",
              scrub: 0.5
            }
          }
        );
      });

      const targets = document.querySelectorAll(".tgi-fridays-namespace .split-text");
      targets.forEach((target) => {
        const SplitClient = new SplitText(target, { type: "words,chars" });
        const chars = SplitClient.chars; //an array of all the divs that wrap each character
        const rotate = target.dataset.rotate || '0';
        const y = Number(target.dataset.y) || 0;
        const x = Number(target.dataset.x) || 0;
      
        gsap.from(chars, {
          duration: 1,
          opacity: 0,
          rotate,
          x,
          y,
          ease: "none",
          stagger: 0.4,
          scrollTrigger: {
            trigger: target,
            start: "top 75%",
            end: "bottom center",
            scrub: true
          }
        });
      });
      
      const svgImages = document.querySelectorAll('.tgi-fridays--stripe');
      svgImages.forEach((svg) => {
        const paths = svg.querySelectorAll('path');
        const rawPath = MotionPathPlugin.getRawPath(paths);
        let point;
        MotionPathPlugin.cacheRawPathMeasurements(rawPath);
      
        const start = svg.dataset.start || "top center";
        const end = svg.dataset.end || "+=100%";
        gsap.timeline({
          scrollTrigger:{
            trigger: svg,
            pin: false,
            scrub: true,
            start,
            end,
            onUpdate: ({progress}) => {
              point = MotionPathPlugin.getPositionOnPath(rawPath, progress, true);
            }
          },
          defaults:{ease:'none'}
        })
          .from(paths, {drawSVG: '0', duration: 1}, 0)
      
      })

      // 
      ScrollTrigger.saveStyles(".scroll-marquee--first");

      ScrollTrigger.matchMedia({
          
          // the mobile animations are the same as the desktop, except the font colors are different
          // the scrub is delayed by 1s with relation to the scrollbar (scrub: 1)
          // the animation starts a bit earlier
          "(max-width: 768px)": function() {
            
            const mobileTL = gsap.timeline({
              scrollTrigger: {
                trigger: ".scroll-marquee",
                start: "-100% bottom",
                scrub: 1,
              }
            });

            mobileTL.to(".scroll-marquee--first", {duration: 2, xPercent: -50});
          },
          
          // The tablet and desktop animations have a delay of 7s on scrubs (scrub: 2).
          "(min-width: 769px)": function() {

            const desktopTL = gsap.timeline({
              scrollTrigger: {
                trigger: ".scroll-marquee",
                start: "-40% bottom",
                scrub: 7,
              }
            });

            desktopTL.to(".scroll-marquee--first", {duration: 2, xPercent: -50});
          }
        });



    }
  }, [])

  return (
    <Layout page="work" route="/portfolio/tgi-fridays">
      <Seo 
        image={'/tgi-fridays/cover.jpg'}
        title="TGI Fridays by Alistair Bancroft"
        description={'Reimagining the TGI Fridays digital presence from the ground up, offering a seamless, engaging, and user-friendly experience to their customers across the globe.'}
      />
      <div className="tgi-fridays-namespace">
        <link href="https://fonts.googleapis.com/css2?family=REM:wght@400;500;600;700&family=Crimson+Pro:wght@400;500;600;700&display=swap" rel="stylesheet"></link>

        <ProjectHero
          background="tgiFridays"
          agency={'Inviqa'}
          body={["Reimagining the TGI Fridays digital presence from the ground up, offering a seamless, engaging, and user-friendly experience to their customers across the globe. This project encompassed a 'greenfield' overhaul of the existing website and the creation of a bespoke booking widget, incorporating modern aesthetics and a visually captivating interface that perfectly aligns with the brand's core values."]}
          engagement={'2023'}
          technologies={'Drupal, React, GSAP, TailwindCSS'}
          position={'Senior Front End / Inviqa'}
          project={{
            label: 'TGI FRIDAYS',
          }}
          title={'Project: TGI Fridays'}
          subtitle={'Revitalising the TGI Fridays online experience'}
          url={{
            href: 'https://www.tgifridays.co.uk',
            label: 'www.tgifridays.co.uk',
          }}
        />
        
        <section data-background="ltTgiFridays" className="aspect-square md:aspect-[1920/1080] flex font-rem overflow-hidden relative w-full inview">
          <div className="absolute h-full left-0 top-0 w-full tgi-fridays--loading">
            {[...Array(7)].map((e, i) => <div key={`tgi-fridays__stripe-${i}`} className="absolute bg-tgiFriday-primary w-[7.69%] tgi-fridays--loading__stripe"></div>)}
          </div>
          <div data-speed="1.1" className="tgi-fridays--loading__block">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152.47 37.43">
              <title>TGI Fridays Logo</title>
              <path d="M152.47,0H0V37.43H152.47Z" fill="#fff"/>
              <path d="M23,30.71h6.2V21.89h9.07V16.56H29.2V12.3H39.3V6.71H23Z" fill="#010101"/>
              <path d="M53.64,15.23a3,3,0,0,1-.87,2.3,3.46,3.46,0,0,1-2.46.82h-3V12.1h3a3.66,3.66,0,0,1,2.46.77A2.72,2.72,0,0,1,53.64,15.23Zm5,4.05a7.67,7.67,0,0,0,1.18-4.36v-.15a8.89,8.89,0,0,0-.56-3.24,6.21,6.21,0,0,0-1.54-2.35q-2.39-2.46-7.22-2.46H41.09v24h6.15V23.28h2.2l4.05,7.43h7L55.53,22A8,8,0,0,0,58.66,19.28Z" fill="#010101"/>
              <path d="M68.19,6.71H62v24h6.2Z" fill="#010101"/>
              <path d="M84.84,18.87c0,2.15-.51,3.74-1.59,4.77a5.79,5.79,0,0,1-4.15,1.53H77.31v-13H79.1a5.7,5.7,0,0,1,4.15,1.54c1.08,1,1.59,2.62,1.59,4.82Zm6.3-.05v-.41a14.42,14.42,0,0,0-.76-4.82A10.12,10.12,0,0,0,88,9.89a10.37,10.37,0,0,0-3.84-2.35,14.42,14.42,0,0,0-5.13-.82H71.17v24H78.9a13.64,13.64,0,0,0,5.18-.87,10.35,10.35,0,0,0,3.84-2.41,10.72,10.72,0,0,0,2.35-3.74A13.61,13.61,0,0,0,91.14,18.82Z" fill="#010101"/>
              <path d="M99.19,21.53l2.25-7,2.25,7ZM98.36,6.71,90,30.71h6.35l1.35-4.26h7.53l1.4,4.26h6.46l-8.42-24Z" fill="#010101"/>
              <path d="M123.11,6.71l-4,8.67-4-8.67H108.2l7.74,15v9h6.2V21.63l7.68-14.92Z" fill="#010101"/>
              <path d="M144,29a6.88,6.88,0,0,0,1.79-2.36,7.57,7.57,0,0,0,.66-3.13v-.1a6,6,0,0,0-.51-2.67,6.48,6.48,0,0,0-1.43-2,8.23,8.23,0,0,0-2.31-1.53A31.89,31.89,0,0,0,139.1,16a11.21,11.21,0,0,1-1.85-.76,8.26,8.26,0,0,1-1.18-.67,3.51,3.51,0,0,1-.61-.61,1.54,1.54,0,0,1-.15-.72v0a1.43,1.43,0,0,1,.56-1.13,2.58,2.58,0,0,1,1.64-.41,7.52,7.52,0,0,1,2.92.62,11.71,11.71,0,0,1,2.66,1.59l3.08-4.57a13,13,0,0,0-4-2.25,14.66,14.66,0,0,0-4.56-.67,11.07,11.07,0,0,0-3.53.57,7.78,7.78,0,0,0-2.72,1.54,6.76,6.76,0,0,0-1.69,2.3,7.19,7.19,0,0,0-.61,3v.1a6.56,6.56,0,0,0,.56,2.82,6.82,6.82,0,0,0,1.59,2,9.73,9.73,0,0,0,2.46,1.48c.92.41,2,.82,3.13,1.24.71.25,1.28.46,1.74.66a5.67,5.67,0,0,1,1.08.61,3.49,3.49,0,0,1,.56.62,1.43,1.43,0,0,1,.15.66v.06a1.62,1.62,0,0,1-.61,1.28,3.24,3.24,0,0,1-1.85.46,7.81,7.81,0,0,1-3.38-.77,14.4,14.4,0,0,1-3.13-1.95l-3.27,4.51a13.17,13.17,0,0,0,4.6,2.67,16,16,0,0,0,5.18.82,11.14,11.14,0,0,0,3.54-.52A9.71,9.71,0,0,0,144,29Z" fill="#010101"/>
              <path d="M9.48,21.68H6.66v9H9.48v-3H18.7V24.66H9.48Z" fill="#010101"/>
              <path d="M11.68,11.38V16.2h2.41V14.31h1.79a2.16,2.16,0,0,1,.36,1.38c0,1.54-1.23,2.72-3.43,2.72h-.16c-2,0-3.33-1.18-3.33-2.67a3.31,3.31,0,0,1,1-2.36L7.94,11.64A6.3,6.3,0,0,0,6.5,15.79a5.74,5.74,0,0,0,6.1,5.8h.26c3.74,0,6-2.46,6-5.75a7.35,7.35,0,0,0-1.49-4.4H11.68v-.06Z" fill="#010101"/>
              <path d="M18.7,6.71h-12V9.79h12Z" fill="#010101"/>
            </svg>
          </div>
        </section>
        
        <ProjectTitle
          background="ltTgiFridays"
          text={{
            title: 'tgiFridays',
          }}
          content={[{
            title: 'Brand Identity Reinforcement',
            body: ["The website relaunch served as a powerful tool to reinforce TGI Fridays updated brand identity.  Working in unison with the team of designers to ensure that every element, from the new logos to the overall visual aesthetics, reflects the essence of the brand."]
          }]}
        />
        

        <section data-background="ltTgiFridays" className="aspect-square md:aspect-[1920/1080] font-rem overflow-hidden relative w-full tgi-fridays--stripe-2">
          <StaticImage placeholder="blurred"
            alt="TGI Fridays"
            className="h-full object-cover w-full tgi-fridays--img"
            src="../../images/portfolio/tgi-fridays/tgi-fridays__stripe-2.jpg"
          />
          <div data-speed="1.1" className="absolute flex h-full items-center left-0 m-auto p-5 md:px-14 top-0 w-full z-10">
            <div>
              <div className="font-black text-3xl lg:text-[4vw] uppercase heading">
                <span>Kids eat free</span>
              </div>
              <div className="font-crimson text-lg lg:text-[1.35vw] subtitle">
                <span>For new and existing Stripes Rewards Members</span>
              </div>
              <div className="tgi-fridays__btn tgi-fridays__btn--primary tgi-fridays__btn--inverted">
                <div className="tgi-fridays__btn__inner">
                  <div className="tgi-fridays__btn__icon ml-2">
                    <svg className="stroke-current" viewBox="0 0 24 24" fill="none">
                      <path d="M21.9478 12.1251L1 12.1719" strokeWidth="3.5" strokeMiterlimit="10"></path>
                      <path d="M22.5462 12.125C15.3642 12.125 10.8754 19.3071 9.07983 22.0004" strokeWidth="3.5" strokeMiterlimit="10"></path>
                      <path d="M22.5462 12.125C15.3642 12.125 10.8754 4.94291 9.07983 2.24963" strokeWidth="3.5" strokeMiterlimit="10"></path>
                    </svg>
                  </div>
                  <span>Kids eat free</span>
                </div>
              </div>
            </div>
          </div>
          <svg className="absolute right-0 top-0 tgi-fridays--stripe" viewBox="0 0 580 800" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M123.306 -20.8572C127.638 67.617 146.079 275.858 232.237 404.646C394.325 646.933 648.124 722.232 796.025 741.976C879.62 756.171 1087.28 784.983 1306.16 730.038" stroke="#ED0000" strokeWidth="200"/>
            <path d="M123.306 -20.8572C127.638 67.617 146.079 275.858 232.237 404.646C394.325 646.933 648.124 722.232 796.025 741.976C879.62 756.171 1087.28 784.983 1306.16 730.038" stroke="white" strokeWidth="120"/>
            <path d="M123.306 -20.8572C127.638 67.617 146.079 275.858 232.237 404.646C394.325 646.933 648.124 722.232 796.025 741.976C879.62 756.171 1087.28 784.983 1306.16 730.038" stroke="#ED0000" strokeWidth="40"/>
          </svg>
        </section>
        <ProjectTitle 
          background="ltTgiFridays"
          text={{
            title: 'tgiFridays',
          }}
          content={[{
            title: 'Elevating the user experience',
            body: ["By elevating the user experience with smooth page scroll transitions, we aimed to breathe life into the website, creating a seamless and engaging experience that immersed users in the vibrant world of TGI Fridays."]
          }]}
        />
        
        <section data-background="ltTgiFridays" className="aspect-[1600/442] font-rem mb-[150px] relative w-full tgi-fridays--stripe-1"> 
          <svg className="aspect-[1600/442] absolute inset-y-0 my-auto tgi-fridays--stripe" data-start="top bottom" width="2000" height="522" viewBox="0 0 2000 522" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2672 763C1855.47 276.914 1521.92 215.247 1221.27 207.358C1030.71 202.357 772.059 281.099 607.445 283.282C261.037 287.875 -52.5294 128.917 -567.92 -263.238" stroke="#ED0000" strokeWidth="200"/>
            <path d="M2672 763C1855.47 276.914 1521.92 215.247 1221.27 207.358C1030.71 202.357 772.059 281.099 607.445 283.282C261.037 287.875 -52.5294 128.917 -567.92 -263.238" stroke="white" strokeWidth="120"/>
            <path d="M2672 763C1855.47 276.914 1521.92 215.247 1221.27 207.358C1030.71 202.357 772.059 281.099 607.445 283.282C261.037 287.875 -52.5294 128.917 -567.92 -263.238" stroke="#ED0000" strokeWidth="40"/>
          </svg>
        </section>

        
        <section data-background="ltTgiFridays" className="aspect-square md:aspect-[1920/1080] font-rem mb-[300px] relative w-full tgi-fridays--stripe-3">
          <div className="h-full overflow-hidden">
            <StaticImage placeholder="blurred"
              alt="TGI Fridays"
              className="h-full object-cover w-full tgi-fridays--img"
              src="../../images/portfolio/tgi-fridays/tgi-fridays__stripe.jpg"
            />
          </div>
          <svg className="aspect-[1600/532] absolute top-[70%] md:top-[52%] tgi-fridays--stripe" data-start="top center" viewBox="0 0 2000 665" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-162.5 644.5C19.5 517.5 303.5 445.732 383 425C638 358.5 824.236 449.128 984.5 464.5C1267.5 491.645 1504 373.5 1680.5 238C1785.99 157.011 1934.5 80 2141.5 101" stroke="#ED0000" strokeWidth="200"/>
            <path d="M-162.5 644.5C19.5 517.5 303.5 445.732 383 425C638 358.5 824.236 449.128 984.5 464.5C1267.5 491.645 1504 373.5 1680.5 238C1785.99 157.011 1934.5 80 2141.5 101" stroke="white" strokeWidth="120"/>
            <path d="M-162.5 644.5C19.5 517.5 303.5 445.732 383 425C638 358.5 824.236 449.128 984.5 464.5C1267.5 491.645 1504 373.5 1680.5 238C1785.99 157.011 1934.5 80 2141.5 101" stroke="#ED0000" strokeWidth="40"/>
          </svg>
        </section>

        <div className="mb-[300px]">
        <section data-background="ltTgiFridays" className="md:aspect-[1920/1080] md:flex font-rem overflow-hidden relative w-full">
          <div className="aspect-square md:aspect-auto relative w-full">
            <div data-speed="1.1" className="flex h-full items-center left-0 m-auto p-5 md:px-14 relative top-0 w-full z-10">
              <div>
                <div className="font-black text-3xl lg:text-[4vw] uppercase heading split-text" data-rotate="0" data-x="0" data-y="10">
                  <span>COCKTAIL MASTERCLASS</span>
                </div>
                <div className="font-crimson text-lg lg:text-[1.35vw] subtitle split-text" data-rotate="45deg" data-x="-10" data-y="10">
                  <span>Step up to the bar and test your mixing skills</span>
                </div>
                <div className="tgi-fridays__btn tgi-fridays__btn--primary tgi-fridays__btn--inverted">
                  <div className="tgi-fridays__btn__inner split-text" data-rotate="45deg" data-x="-10" data-y="10">
                    <div className="tgi-fridays__btn__icon ml-2">
                      <svg className="stroke-current" viewBox="0 0 24 24" fill="none">
                        <path d="M21.9478 12.1251L1 12.1719" strokeWidth="3.5" strokeMiterlimit="10"></path>
                        <path d="M22.5462 12.125C15.3642 12.125 10.8754 19.3071 9.07983 22.0004" strokeWidth="3.5" strokeMiterlimit="10"></path>
                        <path d="M22.5462 12.125C15.3642 12.125 10.8754 4.94291 9.07983 2.24963" strokeWidth="3.5" strokeMiterlimit="10"></path>
                      </svg>
                    </div>
                    <span>Book now</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute h-full left-0 top-0 w-full z-0">
              <StaticImage placeholder="blurred"
                alt="TGI Fridays"
                className="h-full object-cover w-full"
                src="../../images/portfolio/tgi-fridays/tgi-fridays__banner-2.jpg"
              />
            </div>
          </div>
          <div className="aspect-square md:aspect-auto relative w-full">
            <div data-speed="1.1" className="flex h-full items-center left-0 m-auto p-5 md:px-14 relative top-0 w-full z-10">
              <div>
                <div className="font-black text-3xl lg:text-[4vw] uppercase heading heading--inverted split-text" data-rotate="0" data-x="0" data-y="10">
                  <span>Bottomless Brunch</span>
                </div>
                <div className="font-crimson text-lg lg:text-[1.35vw] subtitle subtitle--inverted split-text" data-rotate="45deg" data-x="-10" data-y="10">
                  <span>Where better to brunch than the Original American Cocktail Bar?</span>
                </div>
                <div className="tgi-fridays__btn tgi-fridays__btn--primary">
                  <div className="tgi-fridays__btn__inner split-text" data-rotate="45deg" data-x="-10" data-y="10">
                    <div className="tgi-fridays__btn__icon ml-2">
                      <svg className="stroke-current" viewBox="0 0 24 24" fill="none">
                        <path d="M21.9478 12.1251L1 12.1719" strokeWidth="3.5" strokeMiterlimit="10"></path>
                        <path d="M22.5462 12.125C15.3642 12.125 10.8754 19.3071 9.07983 22.0004" strokeWidth="3.5" strokeMiterlimit="10"></path>
                        <path d="M22.5462 12.125C15.3642 12.125 10.8754 4.94291 9.07983 2.24963" strokeWidth="3.5" strokeMiterlimit="10"></path>
                      </svg>
                    </div>
                    <span>Book now</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute h-full left-0 top-0 w-full z-0">
              <StaticImage placeholder="blurred"
                alt="TGI Fridays"
                className="h-full object-cover w-full"
                src="../../images/portfolio/tgi-fridays/tgi-fridays__banner-3.jpg"
              />
            </div>
          </div>
        </section>
        <ProjectTitle 
          background="ltTgiFridays"
          text={{
            title: 'tgiFridays',
          }}
          content={[{
            title: 'Elevating UX with Animated Copy',
            body: ["By seamlessly incorporating innovative design elements like split text and animated copy, we've significantly enhanced the user experience on the front-end.  These interactive features have infused our website with dynamic content, fostering greater engagement and memorability for TGI Fridays visitors."]
          }]}
        />
        </div>
        
        
        <section data-background="ltTgiFridays" className="font-rem relative w-full">
          <div className="tgi-marquee bg-tgiFriday-primary font-crimson text-white border-y-2 border-tgiFriday-offBlack ">
            <ul aria-hidden="true" className="tgi-marquee__content font-black text-xl lg:text-2xl leading-none">
            <li>What will you choose?</li>
                  <li className="text-tgiFriday-offBlack last:mr-4">•</li>
                  <li>What will you choose?</li>
                  <li className="text-tgiFriday-offBlack last:mr-4">•</li>
                  <li>What will you choose?</li>
                  <li className="text-tgiFriday-offBlack last:mr-4">•</li>
                  <li>What will you choose?</li>
                  <li className="text-tgiFriday-offBlack last:mr-4">•</li>
                  <li>What will you choose?</li>
                  <li className="text-tgiFriday-offBlack last:mr-4">•</li>
                  <li>What will you choose?</li>
                  <li className="text-tgiFriday-offBlack last:mr-4">•</li>
            </ul>
            <ul aria-hidden="true" className="tgi-marquee__content font-black text-xl lg:text-2xl leading-none">
            <li>What will you choose?</li>
                  <li className="text-tgiFriday-offBlack last:mr-4">•</li>
                  <li>What will you choose?</li>
                  <li className="text-tgiFriday-offBlack last:mr-4">•</li>
                  <li>What will you choose?</li>
                  <li className="text-tgiFriday-offBlack last:mr-4">•</li>
                  <li>What will you choose?</li>
                  <li className="text-tgiFriday-offBlack last:mr-4">•</li>
                  <li>What will you choose?</li>
                  <li className="text-tgiFriday-offBlack last:mr-4">•</li>
                  <li>What will you choose?</li>
                  <li className="text-tgiFriday-offBlack last:mr-4">•</li>
            </ul>
          </div>
          <div className="tgi-marquee bg-tgiFriday-primary font-crimson text-white border-y-2 mb-16 md:mb-20 border-tgiFriday-offBlack ">
            <ul aria-hidden="true" className="tgi-marquee__content font-black text-xl lg:text-2xl leading-none">
              <li>Raising Spirits Since &apos;65</li>
              <li className="text-tgiFriday-offBlack last:mr-4">•</li>
              <li>Raising Spirits Since &apos;65</li>
              <li className="text-tgiFriday-offBlack last:mr-4">•</li>
              <li>Raising Spirits Since &apos;65</li>
              <li className="text-tgiFriday-offBlack last:mr-4">•</li>
              <li>Raising Spirits Since &apos;65</li>
              <li className="text-tgiFriday-offBlack last:mr-4">•</li>
              <li>Raising Spirits Since &apos;65</li>
              <li className="text-tgiFriday-offBlack last:mr-4">•</li>
              <li>Raising Spirits Since &apos;65</li>
              <li className="text-tgiFriday-offBlack last:mr-4">•</li>
            </ul>
            <ul aria-hidden="true" className="tgi-marquee__content font-black text-xl lg:text-2xl leading-none">
              <li>Raising Spirits Since &apos;65</li>
              <li className="text-tgiFriday-offBlack last:mr-4">•</li>
              <li>Raising Spirits Since &apos;65</li>
              <li className="text-tgiFriday-offBlack last:mr-4">•</li>
              <li>Raising Spirits Since &apos;65</li>
              <li className="text-tgiFriday-offBlack last:mr-4">•</li>
              <li>Raising Spirits Since &apos;65</li>
              <li className="text-tgiFriday-offBlack last:mr-4">•</li>
              <li>Raising Spirits Since &apos;65</li>
              <li className="text-tgiFriday-offBlack last:mr-4">•</li>
              <li>Raising Spirits Since &apos;65</li>
              <li className="text-tgiFriday-offBlack last:mr-4">•</li>
            </ul>
          </div>
        
        </section>
        <ProjectTitle 
          background="ltTgiFridays"
          text={{
            title: 'tgiFridays',
          }}
          content={[{
            title: 'Embracing Marquee',
            body: ["To create dynamic and captivating user experiences, we have integrated the HTML/CSS Marquee component into our design toolkit. The Marquee component enables our audience to stay engaged and informed as they explore our website.", 'By leveraging this versatile tool, we elevate our storytelling potential, allowing key messages and highlights to gracefully scroll across the screen.']
          }]}
        />

          <div className="scroll-marquee font-crimson ">
            <div className="scroll-marquee__inner scroll-marquee--first">
              <span>Celebrate with us</span>
              <span className="text-tgiFriday-offBlack ">•</span>
              <span>Celebrate with us</span>
              <span className="text-tgiFriday-offBlack ">•</span>
              <span>Celebrate with us</span>
              <span className="text-tgiFriday-offBlack ">•</span>
              <span>Celebrate with us</span>
              <span className="text-tgiFriday-offBlack ">•</span>
              <span>Celebrate with us</span>
              <span className="text-tgiFriday-offBlack ">•</span>
              <span>Celebrate with us</span>
              <span className="text-tgiFriday-offBlack ">•</span>
              <span>Celebrate with us</span>
              <span className="text-tgiFriday-offBlack ">•</span>
              <span>Celebrate with us</span>
              <span className="text-tgiFriday-offBlack ">•</span>
              <span>Celebrate with us</span>
              <span className="text-tgiFriday-offBlack ">•</span>
              <span>Celebrate with us</span>
              <span className="text-tgiFriday-offBlack ">•</span>
            </div>
          </div>
       
        
        {/* <ProjectTitle title={'Mobile-First Approach'} body={"Understanding the significance of mobile devices in our clients users lives, we developed the website with a mobile-first strategy. The website has been optimised for various screen sizes, offering a consistent experience regardless of the device used."} />
        <div className="font-rem h-screen">
          <div className="tgi-fridays__btn">
            <div className="tgi-fridays__btn__inner">
              <div className="tgi-fridays__btn__icon">
                <svg className="stroke-current" viewBox="0 0 24 24" fill="none">
                  <path d="M21.9478 12.1251L1 12.1719" strokeWidth="3.5" strokeMiterlimit="10"></path>
                  <path d="M22.5462 12.125C15.3642 12.125 10.8754 19.3071 9.07983 22.0004" strokeWidth="3.5" strokeMiterlimit="10"></path>
                  <path d="M22.5462 12.125C15.3642 12.125 10.8754 4.94291 9.07983 2.24963" strokeWidth="3.5" strokeMiterlimit="10"></path>
                </svg>
              </div>
              View menus
            </div>
          </div>

          <div className="tgi-fridays__btn-default ml-4">
            <div className="tgi-fridays__btn-default__inner">
              <div className="tgi-fridays__btn-default__icon">
                <svg className="stroke-current" viewBox="0 0 24 24" fill="none">
                  <path d="M21.9478 12.1251L1 12.1719" strokeWidth="3.5" strokeMiterlimit="10"></path>
                  <path d="M22.5462 12.125C15.3642 12.125 10.8754 19.3071 9.07983 22.0004" strokeWidth="3.5" strokeMiterlimit="10"></path>
                  <path d="M22.5462 12.125C15.3642 12.125 10.8754 4.94291 9.07983 2.24963" strokeWidth="3.5" strokeMiterlimit="10"></path>
                </svg>
            </div>
            Book a table
            </div>
          </div>

          <div className="tgi-marquee bg-tgiFriday-primary font-crimson text-white py-3 md:py-4 border-y-2 lg:border-y-3 border-tgiFriday-offBlack ">
            <ul aria-hidden="true" className="tgi-marquee__content font-black text-xl lg:text-2xl leading-none">
              {[...Array(6)].map((e, i) => (
                <>
                  <li>What will you choose?</li>
                  <li className="text-tgiFriday-offBlack last:mr-4">•</li>
                </>
              ))}
            </ul>
            <ul aria-hidden="true" className="tgi-marquee__content font-black text-xl lg:text-2xl leading-none">
              {[...Array(6)].map((e, i) => (
                <>
                  <li>What will you choose?</li>
                  <li className="text-tgiFriday-offBlack last:mr-4">•</li>
                </>
              ))}
            </ul>
          </div>
        </div> */}
      </div>
    </Layout>
  )
}

export default TGIFridaysPage

import React, { useCallback, useEffect, useState } from "react"
import { PageProps } from "gatsby"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { SplitText } from "gsap/SplitText"
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import "../../styles/black-plaque-project.css"

import ProjectHero from "../../components/ProjectHero"
import ProjectTitle from "../../components/ProjectTitle"
import { StaticImage } from "gatsby-plugin-image"
  
const center = { lat: 51.507351, lng: -0.127758 }
const markers = [
    { id: 'marker_0', lat: 51.5134255, lng: -0.135384 },
    { id: 'marker_1', lat: 51.5403233, lng: -0.1399203 },
    { id: 'marker_2', lat: 51.5084592, lng: -0.1380165 },
    { id: 'marker_3', lat: 51.5110001, lng: -0.0642805 },
    { id: 'marker_4', lat: 51.4951012, lng: -0.1460569 },
];
const darkMapStyle = [
    { elementType: "geometry", stylers: [{ color: "#0f100f" }] },
    { elementType: "labels", stylers: [{ visibility: "off" }] },
    { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#0f100f" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#0f100f" }] },
    { featureType: "poi.business", stylers: [{ color: "#0f100f" }, { visibility: "off" }] },
    { featureType: "administrative", elementType: "geometry", stylers: [{ color: "#0f100f" }, { visibility: "off" }] },
    { featureType: "administrative.country", elementType: "labels.text.fill", stylers: [{ color: "#0f100f" }] },
    { featureType: "administrative.land_parcel", stylers: [{ visibility: "off" }] },
    { featureType: "administrative.locality", elementType: "labels.text.fill", stylers: [{ color: "#bdbdbd" }] },
    { featureType: "administrative.neighborhood", stylers: [{ visibility: "off" }] },
    { featureType: "poi", stylers: [{ visibility: "off" }] },
    { featureType: "poi", elementType: "labels.text.fill", stylers: [{ color: "#0f100f" }] },
    { featureType: "poi.park", elementType: "geometry", stylers: [{ color: "#181818" }] },
    { featureType: "poi.park", elementType: "labels.text.fill", stylers: [{ color: "#616161" }] },
    { featureType: "poi.park", elementType: "labels.text.stroke", stylers: [{ color: "#1b1b1b" }] },
    { featureType: "road", elementType: "geometry.fill", stylers: [{ color: "#525252" }] },
    { featureType: "road", elementType: "labels.icon", stylers: [{ visibility: "off" }] },
    { featureType: "road", elementType: "labels.text.fill", stylers: [{ color: "#8a8a8a" }] },
    { featureType: "road.arterial", elementType: "geometry", stylers: [{ color: "#373737" }] },
    { featureType: "road.highway", elementType: "geometry", stylers: [{ color: "#3c3c3c" }] },
    { featureType: "road.highway.controlled_access", elementType: "geometry", stylers: [{ color: "#4e4e4e" }] },
    { featureType: "road.local", elementType: "labels.text.fill", stylers: [{ color: "#616161" }] },
    { featureType: "transit", stylers: [{ visibility: "off" }] },
    { featureType: "transit", elementType: "labels.text.fill", stylers: [{ color: "#0f100f" }] },
    { featureType: "water", elementType: "geometry", stylers: [{ color: "#000000" }] },
    { featureType: "water", elementType: "labels.text.fill", stylers: [{ color: "#000000" }] }
  ];

const BlackPlaquePage = (props: PageProps) => {
    // GSAP
    gsap.config();
    gsap.registerPlugin(ScrollTrigger, SplitText);

    ScrollTrigger.defaults({ ignoreMobileResize: true });

    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: 'AIzaSyAGVwtJNzJuBEm9sLxIUBdyXxgNXOD6n_Q',
    });
    const [map, setMap] = useState(null);

    const onLoad = useCallback(function callback(map) {
        if (map && markers.length > 0) {
            const bounds = new window.google.maps.LatLngBounds(center);
            markers.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
            map.fitBounds(bounds);
            setMap(map)
          }
    
    }, []);

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, []);

    useEffect(() => {
        const tracks = gsap.utils.toArray(".sticky-element");
        const titleRight = document.querySelector('.title-right');
        const titleLeft = document.querySelector('.title-left');

        gsap.fromTo(
            titleRight,
            { 
              transform: 'translateX(200%)',
              opacity: 0, 
            },
            {
              transform: 'translateX(0%)',
              scale: 1,
              opacity: 1,
              ease: "none",
              force3D: true,
              scrollTrigger: {
                trigger: titleRight,
                start: "top bottom",
                end: "top center",
                pinType: "transform",
                scrub: 2,
              }
            }
          );

          gsap.fromTo(
            titleLeft,
            { 
              transform: 'translateX(-200%)',
              opacity: 0, 
            },
            {
              transform: 'translateX(0%)',
              scale: 1,
              opacity: 1,
              ease: "none",
              force3D: true,
              scrollTrigger: {
                trigger: titleLeft,
                start: "top bottom",
                end: "top center",
                pinType: "transform",
                scrub: 2,
              }
            }
          );

        //   gsap.set('.image-fade .image-fade__asset',{xPercent:-50, yPercent:-50})

const imgs = gsap.utils.toArray(".image-fade__asset");
const next = 2; // time to change
const fade = 1; // fade time 



//only for the first 
gsap.set(imgs[0], {autoAlpha:1})

// ====================
function crossfade(){
  const action = gsap.timeline()
  .to(imgs[0], {autoAlpha:0, duration:fade})
  .to(imgs[1], {autoAlpha:1, duration:fade},0);

  imgs.push( imgs.shift() );
  // start endless run
  gsap.delayedCall(next, crossfade);
}

// start the crossfade after next = 3 sec
gsap.delayedCall(next, crossfade);

        tracks.forEach((track, i) => {
            let trackWrapper = track.querySelectorAll(".track");
            let allImgs = track.querySelectorAll(".image");
    
            let trackWrapperWidth = () => {
              let width = 0;
              trackWrapper.forEach(el => width += el.offsetWidth);
              return width;
            }
            
            let scrollTween = gsap.to(trackWrapper, {
              x: () => -trackWrapperWidth() + window.innerWidth,
              scrollTrigger: {
                trigger: track,
                pin: true,
                scrub: 1,
                start: "center center",
                end: () => "+=" + (track.scrollWidth - window.innerWidth),
                onRefresh: self => self.getTween().resetTo("totalProgress", 0), // fixes a very minor issue where the progress was starting at 0.001.
                invalidateOnRefresh: true,
                id: "id-one"
              },
            });
    
            allImgs.forEach((img, i) => {
              // the intended parallax animation
              gsap.fromTo(img, {
                x: "-60px"
              }, {
                x: "60px",
                scrollTrigger: {
                  trigger: img.parentNode, //.panel-wide
                  containerAnimation: scrollTween,
                  start: "left right",
                  end: "right left",
                  scrub: true,
                  invalidateOnRefresh: true,
                  onRefresh: self => {
                    if (self.start < 0) {
                      self.animation.progress(gsap.utils.mapRange(self.start, self.end, 0, 1, 0));
                    }
                  },
                },
              });
            });
        })


    }, [])

    return (
        <Layout page="work" route="/portfolio/black-plaque-project">
            <Seo
                image={'/black-plaque-project/cover.jpg'}
                title="Black Plaque Project by Alistair Bancroft"
                description={"Crafting an engaging digital platform to honour the historical contributions of black individuals"}
            />
          
            <div className="black-plaque-project-namespace">
                <ProjectHero
                    background={'black'}
                    agency={'Havas London'}
                    body={[
                        "When you've explored London, you might have noticed the famous blue plaques on buildings, honouring notable figures who've shaped British history. Strikingly, only 1.6% of these plaques celebrate African or Caribbean heritage, despite the immense contributions.",
                        "The Black Plaque Project, a collaboration between Havas London and the Nubian Jak Community Trust, took action to redress this imbalance. It introduced specially designed black plaques to highlight the lives of London's remarkable Black residents.  By placing these black plaques alongside the blue ones, the project aimed to provide a more inclusive view of London's past and ensuring the recognition of Black contributions.  Each plaque tells a new story, celebrating the richness that's always been part of London's identity.",
                    ]}
                    engagement={'2020'}
                    technologies={'Wordpress, ACF, Locomotive Scroll'}
                    position={'Front End Lead / ekino'}
                    project={{
                        label: "BLACK PLAQUE PROJECT",
                    }}
                    title={'Project: Black Plaque Project'}
                    subtitle={'Crafting an engaging digital platform to honour the historical contributions of black individuals'}
                    url={{
                        href: 'https://blackplaqueproject.com/',
                        label: 'www.blackplaqueproject.com',
                      }}
                />


                

                



<div className="bg-black">
<section data-background="black" className="bg-black flex items-center justify-center relative text-white leading-none">
    <div data-speed="1.1" className="absolute top-12 right-5 md:right-12 font-serif italic text-[9.76562vw] md:text-[100px] text-right uppercase title-right z-10">TELLING THE<br />UNTOLD</div>
    <div data-speed="1.2" className="h-[47.85156vw] md:h-[40vw] my-24 mx-auto relative w-[39.84375vw] md:w-[35vw] image-fade">
        <div className="absolute top-0 left-0 h-full w-full image-fade__asset">
            <StaticImage
                placeholder="blurred"
                alt="Black Plaques Project"
                className="h-full object-cover asset"
                src="../../images/portfolio/black-plaques-project/black-plaques--intro-1.png"
            />
        </div>
        <div className="absolute top-0 left-0 h-full w-full image-fade__asset">
            <StaticImage
                placeholder="blurred"
                alt="Black Plaques Project"
                className="h-full object-cover asset"
                src="../../images/portfolio/black-plaques-project/black-plaques--intro-2.png"
            />
        </div>
        <div className="absolute top-0 left-0 h-full w-full image-fade__asset">
            <StaticImage
                placeholder="blurred"
                alt="Black Plaques Project"
                className="h-full object-cover asset"
                src="../../images/portfolio/black-plaques-project/black-plaques--intro-3.png"
            />
        </div>
        <div className="absolute top-0 left-0 h-full w-full image-fade__asset">
            <StaticImage
                placeholder="blurred"
                alt="Black Plaques Project"
                className="h-full object-cover asset"
                src="../../images/portfolio/black-plaques-project/black-plaques--intro-4.png"
            />
        </div>
        <div className="absolute top-0 left-0 h-full w-full image-fade__asset">
            <StaticImage
                placeholder="blurred"
                alt="Black Plaques Project"
                className="h-full object-cover asset"
                src="../../images/portfolio/black-plaques-project/black-plaques--intro-5.png"
            />
        </div>
    </div>
    <div data-speed="1.1" className="absolute block bottom-24 font-serif text-[6.34766vw] md:text-[100px] left-5 md:left-12  text-left uppercase title-left z-10">
        SIDE OF<br /><span className="ml-[11.71875vw] md:ml-[120px]">HISTORY</span>
    </div>
</section>

<section data-background="black" className="bg-black hidden md:flex h-screen items-center justify-start overflow-hidden relative w-full carousel sticky-element">
                    <div className="relative z-10 track">
                        <div className="flex gap-6 items-center justify-start track-flex">
                            <div className="relative panel">
                                <div className="bg-blackPlaques-primary flex flex-col justify-between  overflow-hidden">
                                    <div className="absolute border-2 border-black flex h-10 items-center justify-center left-2 rounded-full top-2 w-10">1</div>
                                    <div className="flex flex-col font-serif h-[237px] italic justify-end leading-none py-1 px-2 text-[46px] text-white uppercase">
                                        <div className="flex justify-end mr-3">
                                            <span>Robert </span>
                                        </div>
                                        <div className="flex break-words">
                                            <span>Wedder­burn</span>
                                        </div>
                                        <div className="my-1 tracking-wider text-[21px] text-black">
                                            Politicial Activist and Abolitionist 
                                        </div>
                                    </div>
                                    <div className="h-[239px] overflow-hidden relative w-full">
                                        <div className="absolute bottom-0 font-serif inset-x-0 mx-auto p-5 pointer-events-none text-[36px] text-center text-white z-20">1762 — 1835</div>
                                        <div className="h-full w-full image">
                                            <div className="h-full relative mix-blend-multiply w-full z-10 image-overlay">
                                                <StaticImage
                                                    placeholder="blurred"
                                                    alt="Black Plaques Project"
                                                    className="h-full object-cover asset"
                                                    src="../../images/portfolio/black-plaques-project/black-plaques--banner-1.png"
                                                />
                                                <div className="absolute bg-blackPlaques-primary h-full -left-1/2 top-0 w-screen"></div>
                                            </div>
                                            <div className="h-full w-full image-under">
                                                <div className="h-full relative w-full z-10">
                                                    <StaticImage
                                                        placeholder="blurred"
                                                        alt="Black Plaques Project"
                                                        className="h-full object-cover asset"
                                                        src="../../images/portfolio/black-plaques-project/black-plaques--banner-1.png"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative panel">
                                <div className="bg-blackPlaques-primary flex flex-col justify-between  overflow-hidden">
                                    <div className="absolute border-2 border-black flex h-10 items-center justify-center left-2 rounded-full top-2 w-10">2</div>
                                    <div className="flex flex-col font-serif h-[237px] italic justify-end leading-none py-1 px-2 text-[46px] text-white uppercase">
                                        <div className="flex justify-end mr-3">
                                            <span>Ladipo</span>
                                        </div>
                                        <div className="flex break-words">
                                            <span>Solanke</span>
                                        </div>
                                        <div className="my-1 tracking-wider text-[21px] text-black">
                                            Activist 
                                        </div>
                                    </div>
                                    <div className="h-[239px] overflow-hidden relative w-full">
                                        <div className="absolute bottom-0 font-serif inset-x-0 mx-auto p-5 pointer-events-none text-[36px] text-center text-white z-20">1886 — 1958</div>
                                        <div className="h-full w-full image">
                                            <div className="h-full relative mix-blend-multiply w-full z-10 image-overlay">
                                                <StaticImage
                                                    placeholder="blurred"
                                                    alt="Black Plaques Project"
                                                    className="h-full object-cover asset"
                                                    src="../../images/portfolio/black-plaques-project/black-plaques--banner-2.png"
                                                />
                                                <div className="absolute bg-blackPlaques-primary h-full -left-1/2 top-0 w-screen"></div>
                                            </div>
                                            <div className="h-full w-full image-under">
                                                <div className="h-full relative w-full z-10">
                                                    <StaticImage
                                                        placeholder="blurred"
                                                        alt="Black Plaques Project"
                                                        className="h-full object-cover asset"
                                                        src="../../images/portfolio/black-plaques-project/black-plaques--banner-2.png"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative panel">
                                <div className="bg-blackPlaques-primary flex flex-col justify-between  overflow-hidden">
                                    <div className="absolute border-2 border-black flex h-10 items-center justify-center left-2 rounded-full top-2 w-10">3</div>
                                    <div className="flex flex-col font-serif h-[237px] italic justify-end leading-none py-1 px-2 text-[46px] text-white uppercase">
                                        <div className="flex justify-end mr-3">
                                            <span>Sarah</span>
                                        </div>
                                        <div className="flex break-words">
                                            <span>Baartman</span>
                                        </div>
                                        <div className="my-1 tracking-wider text-[21px] text-black">
                                            &nbsp; 
                                        </div>
                                    </div>
                                    <div className="h-[239px] overflow-hidden relative w-full">
                                        <div className="absolute bottom-0 font-serif inset-x-0 mx-auto p-5 pointer-events-none text-[36px] text-center text-white z-20">1789 — 1815</div>
                                        <div className="h-full w-full image">
                                            <div className="h-full relative mix-blend-multiply w-full z-10 image-overlay">
                                                <StaticImage
                                                    placeholder="blurred"
                                                    alt="Black Plaques Project"
                                                    className="h-full object-cover asset"
                                                    src="../../images/portfolio/black-plaques-project/black-plaques--banner-3.png"
                                                />
                                                <div className="absolute bg-blackPlaques-primary h-full -left-1/2 top-0 w-screen"></div>
                                            </div>
                                            <div className="h-full w-full image-under">
                                                <div className="h-full relative w-full z-10">
                                                    <StaticImage
                                                        placeholder="blurred"
                                                        alt="Black Plaques Project"
                                                        className="h-full object-cover asset"
                                                        src="../../images/portfolio/black-plaques-project/black-plaques--banner-3.png"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative panel">
                                <div className="bg-blackPlaques-primary flex flex-col justify-between  overflow-hidden">
                                    <div className="absolute border-2 border-black flex h-10 items-center justify-center left-2 rounded-full top-2 w-10">4</div>
                                    <div className="flex flex-col font-serif h-[237px] italic justify-end leading-none py-1 px-2 text-[46px] text-white uppercase">
                                        <div className="flex justify-end mr-3">
                                            <span>Miriam</span>
                                        </div>
                                        <div className="flex break-words">
                                            <span>Makeba</span>
                                        </div>
                                        <div className="my-1 tracking-wider text-[21px] text-black">
                                            Musician
                                        </div>
                                    </div>
                                    <div className="h-[239px] overflow-hidden relative w-full">
                                        <div className="absolute bottom-0 font-serif inset-x-0 mx-auto p-5 pointer-events-none text-[36px] text-center text-white z-20">1932 — 2008</div>
                                        <div className="h-full w-full image">
                                            <div className="h-full relative mix-blend-multiply w-full z-10 image-overlay">
                                                <StaticImage
                                                    placeholder="blurred"
                                                    alt="Black Plaques Project"
                                                    className="h-full object-cover asset"
                                                    src="../../images/portfolio/black-plaques-project/black-plaques--banner-4.png"
                                                />
                                                <div className="absolute bg-blackPlaques-primary h-full -left-1/2 top-0 w-screen"></div>
                                            </div>
                                            <div className="h-full w-full image-under">
                                                <div className="h-full relative w-full z-10">
                                                    <StaticImage
                                                        placeholder="blurred"
                                                        alt="Black Plaques Project"
                                                        className="h-full object-cover asset"
                                                        src="../../images/portfolio/black-plaques-project/black-plaques--banner-4.png"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative panel">
                                <div className="bg-blackPlaques-primary flex flex-col justify-between  overflow-hidden">
                                    <div className="absolute border-2 border-black flex h-10 items-center justify-center left-2 rounded-full top-2 w-10">5</div>
                                    <div className="flex flex-col font-serif h-[237px] italic justify-end leading-none py-1 px-2 text-[46px] text-white uppercase">
                                        <div className="flex justify-end mr-3">
                                            <span>Dyke</span>
                                        </div>
                                        <div className="flex break-words">
                                            <span>Dryden & Wade</span>
                                        </div>
                                        <div className="my-1 tracking-wider text-[21px] text-black">
                                        Entrepreneurs
                                        </div>
                                    </div>
                                    <div className="h-[239px] overflow-hidden relative w-full">
                                        <div className="h-full w-full image">
                                            <div className="h-full relative mix-blend-multiply w-full z-10 image-overlay">
                                                <StaticImage
                                                    placeholder="blurred"
                                                    alt="Black Plaques Project"
                                                    className="h-full object-cover asset"
                                                    src="../../images/portfolio/black-plaques-project/black-plaques--banner-5.png"
                                                />
                                                <div className="absolute bg-blackPlaques-primary h-full -left-1/2 top-0 w-screen"></div>
                                            </div>
                                            <div className="h-full w-full image-under">
                                                <div className="h-full relative w-full z-10">
                                                    <StaticImage
                                                        placeholder="blurred"
                                                        alt="Black Plaques Project"
                                                        className="h-full object-cover asset"
                                                        src="../../images/portfolio/black-plaques-project/black-plaques--banner-5.png"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative panel">
                                <div className="bg-blackPlaques-primary flex flex-col justify-between  overflow-hidden">
                                    <div className="absolute border-2 border-black flex h-10 items-center justify-center left-2 rounded-full top-2 w-10">6</div>
                                    <div className="flex flex-col font-serif h-[237px] italic justify-end leading-none py-1 px-2 text-[46px] text-white uppercase">
                                        <div className="flex justify-end mr-3">
                                            <span>Ethel</span>
                                        </div>
                                        <div className="flex break-words">
                                            <span>Scott</span>
                                        </div>
                                        <div className="my-1 tracking-wider text-[21px] text-black">
                                        Athlete
                                        </div>
                                    </div>
                                    <div className="h-[239px] overflow-hidden relative w-full">
                                        <div className="absolute bottom-0 font-serif inset-x-0 mx-auto p-5 pointer-events-none text-[36px] text-center text-white z-20">1907 — 1984</div>
                                        <div className="h-full w-full image">
                                            <div className="h-full relative mix-blend-multiply w-full z-10 image-overlay">
                                                <StaticImage
                                                    placeholder="blurred"
                                                    alt="Black Plaques Project"
                                                    className="h-full object-cover asset"
                                                    src="../../images/portfolio/black-plaques-project/black-plaques--banner-6.png"
                                                />
                                                <div className="absolute bg-blackPlaques-primary h-full -left-1/2 top-0 w-screen"></div>
                                            </div>
                                            <div className="h-full w-full image-under">
                                                <div className="h-full relative w-full z-10">
                                                    <StaticImage
                                                        placeholder="blurred"
                                                        alt="Black Plaques Project"
                                                        className="h-full object-cover asset"
                                                        src="../../images/portfolio/black-plaques-project/black-plaques--banner-6.png"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section data-background="black" className="bg-black flex md:hidden flex-wrap overflow-hidden py-24 px-2 relative w-full">
                            
                <div className="min-h-[68vw] panel p-2 w-1/2">
                                <div data-speed="1.1" className="h-full relative">
                                    <div className="bg-blackPlaques-primary flex flex-col h-full justify-between  overflow-hidden">
                                        <div className="absolute border-2 border-black flex h-[7vw] items-center justify-center left-2 rounded-full text-[5vw] top-2 w-[7vw]">1</div>
                                        <div className="flex flex-col font-serif h-[50%] italic justify-end leading-none py-1 px-2 text-[7vw] text-white uppercase">
                                            <div className="flex justify-end mr-3">
                                                <span>Robert </span>
                                            </div>
                                            <div className="flex break-words">
                                                <span>Wedder­burn</span>
                                            </div>
                                            <div className="my-1 tracking-wider text-[3vw] text-black">
                                                Politicial Activist and Abolitionist 
                                            </div>
                                        </div>
                                        <div className="h-[50%] overflow-hidden relative w-full">
                                            <div className="absolute bottom-0 font-serif inset-x-0 mx-auto p-5 pointer-events-none text-[5vw] text-center text-white z-20">1762 — 1835</div>
                                            <div className="h-full w-full image">
                                                <div className="h-full relative mix-blend-multiply w-full z-10 image-overlay">
                                                    <StaticImage
                                                        placeholder="blurred"
                                                        alt="Black Plaques Project"
                                                        className="h-full object-cover asset w-full"
                                                        src="../../images/portfolio/black-plaques-project/black-plaques--banner-1.png"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="min-h-[68vw] panel p-2 w-1/2">
                            <div data-speed="1.15" className="h-full relative">
                                <div className="bg-blackPlaques-primary flex flex-col h-full justify-between  overflow-hidden">
                                        <div className="absolute border-2 border-black flex h-[7vw] items-center justify-center left-2 rounded-full text-[5vw] top-2 w-[7vw]">2</div>
                                    <div className="flex flex-col font-serif h-[50%] italic justify-end leading-none py-1 px-2 text-[7vw] text-white uppercase">
                                        <div className="flex justify-end mr-3">
                                            <span>Ladipo</span>
                                        </div>
                                        <div className="flex break-words">
                                            <span>Solanke</span>
                                        </div>
                                        <div className="my-1 tracking-wider text-[3vw] text-black">
                                            Activist 
                                        </div>
                                    </div>
                                    <div className="h-[50%] overflow-hidden relative w-full">
                                            <div className="absolute bottom-0 font-serif inset-x-0 mx-auto p-5 pointer-events-none text-[5vw] text-center text-white z-20">1886 — 1958</div>
                                            <div className="h-full w-full image">
                                                <div className="h-full relative mix-blend-multiply w-full z-10 image-overlay">
                                                    <StaticImage
                                                        placeholder="blurred"
                                                        alt="Black Plaques Project"
                                                        className="h-full object-cover asset w-full"
                                                        src="../../images/portfolio/black-plaques-project/black-plaques--banner-2.png"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                </div>
                            </div>
                            <div className="min-h-[68vw] panel p-2 w-1/2">
                                <div data-speed="1.1" className="h-full relative">
                                    <div className="bg-blackPlaques-primary flex flex-col h-full justify-between  overflow-hidden">
                                        <div className="absolute border-2 border-black flex h-[7vw] items-center justify-center left-2 rounded-full text-[5vw] top-2 w-[7vw]">3</div>
                                        <div className="flex flex-col font-serif h-[50%] italic justify-end leading-none py-1 px-2 text-[7vw] text-white uppercase">
                                            <div className="flex justify-end mr-3">
                                                <span>Sarah</span>
                                            </div>
                                            <div className="flex break-words">
                                                <span>Baartman</span>
                                            </div>
                                            <div className="my-1 tracking-wider text-[3vw] text-black">
                                                &nbsp; 
                                            </div>
                                        </div>
                                        <div className="h-[50%] overflow-hidden relative w-full">
                                            <div className="absolute bottom-0 font-serif inset-x-0 mx-auto p-5 pointer-events-none text-[5vw] text-center text-white z-20">1789 — 1815</div>
                                            <div className="h-full w-full image">
                                                <div className="h-full relative mix-blend-multiply w-full z-10 image-overlay">
                                                    <StaticImage
                                                        placeholder="blurred"
                                                        alt="Black Plaques Project"
                                                        className="h-full object-cover asset w-full"
                                                        src="../../images/portfolio/black-plaques-project/black-plaques--banner-3.png"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                            </div>
                            <div className="min-h-[68vw] panel p-2 w-1/2">
                                <div data-speed="1.15" className="h-full relative">
                                <div className="bg-blackPlaques-primary flex flex-col h-full justify-between  overflow-hidden">
                                    <div className="absolute border-2 border-black flex h-[7vw] items-center justify-center left-2 rounded-full text-[5vw] top-2 w-[7vw]">4</div>
                                        <div className="flex flex-col font-serif h-[50%] italic justify-end leading-none py-1 px-2 text-[7vw] text-white uppercase">
                                            <div className="flex justify-end mr-3">
                                                <span>Miriam</span>
                                            </div>
                                            <div className="flex break-words">
                                                <span>Makeba</span>
                                            </div>
                                            <div className="my-1 tracking-wider text-[3vw] text-black">
                                                Musician
                                            </div>
                                        </div>
                                        <div className="h-[50%] overflow-hidden relative w-full">
                                            <div className="absolute bottom-0 font-serif inset-x-0 mx-auto p-5 pointer-events-none text-[5vw] text-center text-white z-20">1932 — 2008</div>
                                            <div className="h-full w-full image">
                                                <div className="h-full relative mix-blend-multiply w-full z-10 image-overlay">
                                                    <StaticImage
                                                        placeholder="blurred"
                                                        alt="Black Plaques Project"
                                                        className="h-full object-cover asset w-full"
                                                        src="../../images/portfolio/black-plaques-project/black-plaques--banner-4.png"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="min-h-[68vw] panel p-2 w-1/2">
                                <div data-speed="1.1" className="h-full relative">
                                <div className="bg-blackPlaques-primary flex flex-col h-full justify-between  overflow-hidden">
                                    <div className="absolute border-2 border-black flex h-[7vw] items-center justify-center left-2 rounded-full text-[5vw] top-2 w-[7vw]">5</div>
                                        <div className="flex flex-col font-serif h-[50%] italic justify-end leading-none py-1 px-2 text-[7vw] text-white uppercase">
                                            <div className="flex justify-end mr-3">
                                                <span>Dyke</span>
                                            </div>
                                            <div className="flex break-words">
                                                <span>Dryden & Wade</span>
                                            </div>
                                            <div className="my-1 tracking-wider text-[3vw] text-black">
                                            Entrepreneurs
                                            </div>
                                        </div>
                                        <div className="h-[50%] overflow-hidden relative w-full">
                                            <div className="h-full w-full image">
                                                <div className="h-full relative mix-blend-multiply w-full z-10 image-overlay">
                                                    <StaticImage
                                                        placeholder="blurred"
                                                        alt="Black Plaques Project"
                                                        className="h-full object-cover asset w-full"
                                                        src="../../images/portfolio/black-plaques-project/black-plaques--banner-5.png"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="min-h-[68vw] panel p-2 w-1/2">
                                <div data-speed="1.15" className="h-full relative">
                                <div className="bg-blackPlaques-primary flex flex-col h-full justify-between  overflow-hidden">
                                    <div className="absolute border-2 border-black flex h-[7vw] items-center justify-center left-2 rounded-full text-[5vw] top-2 w-[7vw]">6</div>
                                        <div className="flex flex-col font-serif h-[50%] italic justify-end leading-none py-1 px-2 text-[7vw] text-white uppercase">
                                            <div className="flex justify-end mr-3">
                                                <span>Ethel</span>
                                            </div>
                                            <div className="flex break-words">
                                                <span>Scott</span>
                                            </div>
                                            <div className="my-1 tracking-wider text-[3vw] text-black">
                                            Athlete
                                            </div>
                                        </div>
                                        <div className="h-[50%] overflow-hidden relative w-full">
                                            <div className="absolute bottom-0 font-serif inset-x-0 mx-auto p-5 pointer-events-none text-[5vw] text-center text-white z-20">1907 — 1984</div>
                                            <div className="h-full w-full image">
                                                <div className="h-full relative mix-blend-multiply w-full z-10 image-overlay">
                                                    <StaticImage
                                                        placeholder="blurred"
                                                        alt="Black Plaques Project"
                                                        className="h-full object-cover asset w-full"
                                                        src="../../images/portfolio/black-plaques-project/black-plaques--banner-6.png"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </section>
</div>
                <ProjectTitle
                    background={'black'}
                    text={{ 
                        title: 'orange',
                    }}
                    content={[{
                        title: 'Launched at the end of 2020',
                        body:[ 
                            "The project launched at the end of 2020, with 30 individuals set to receive black plaques within the following year. Their incredible narratives came alive on an immersive digital platform, complemented by outdoor posters and in-depth press coverage.  Moreover, the project was bolstered by a dynamic mix of social and digital initiatives, including enlightening podcasts featuring renowned Black cultural commentators."
                        ]
                    }]}
                />


<div className="bg-black">
<section data-background="black" className="bg-black relative text-white leading-none">
    <div data-speed="1.2" className="px-5 md:px-6 py-12 max-w-[942px] mx-auto">
        <div className="text-blackPlaques-primary text-sm uppercase">Plaque</div>
        <div className="text-center w-full">
        <StaticImage
            placeholder="blurred"
            alt="Black Plaques Project"
            className="bg-black mx-auto mb-8 object-contain w-[80%] md:w-[600px]"
            src="../../images/portfolio/black-plaques-project/black-plaques--example-plaque.png"
        />
        </div>
        <div className=" text-left">

        <div className="text-blackPlaques-primary text-sm uppercase">Category</div>
        <div className="font-serif mb-8 text-white text-base">Activist</div>
        <div className="text-blackPlaques-primary text-sm uppercase">LOCATION</div>
        <div className="font-serif mb-8 text-white text-base">4 Hopkins Street, London</div>
        <button className="border border-blackPlaques-primary hover:border-white duration-300 px-4 py-2 rounded-full inline-block text-blackPlaques-primary transition-colors hover:text-white text-sm uppercase">Visit Location</button>
        </div>
        <div className="border-t border-blackPlaques-primary font-serif italic leading-none mt-24 mb-6 tracking-[-1.08px] pt-1 text-blackPlaques-primary text-[34px] md:text-[46px]">THE SON OF A SLAVE, WHOSE IDEALS SCARED THE GOVERNMENT</div>
        <p className="font-serif leading-normal mb-3 text-white text-[18px] md:text-[17px]">Robert Wedderburn was a 19th-century political firebrand, who was so powerful that he was put on a secret British Government list of potentially dangerous reformers.</p>
        <p className="font-serif leading-normal mb-3 text-white text-[18px] md:text-[17px]">His mother was a Jamaican slave who had a child with her owner. His father requested that Wedderburn be born a free man, but then sold his mother to her previous owner while she was pregnant with his third child.</p>
        <p className="font-serif leading-normal mb-3 text-white text-[18px] md:text-[17px]">Although free, Wedderburn lived in harsh living conditions and enlisted in the British Navy to escape them. While in the Navy, he was horrified by the brutality displayed by the British, both on their ships and in their colonies. This, among other experiences in his life would inspire him to shape and characterise the struggles of the British working class.</p>
        <p className="font-serif leading-normal mb-12 text-white text-[18px] md:text-[17px]">He pushed for the end of slavery and the redistribution of wealth in Britain, publishing a book, The Horrors of Slavery, in 1824.</p>
                    
        <div className="border-t border-white/50 pt-6 mb-6 text-white text-sm uppercase">Historic locations</div>
        <div className="h-[600px] relative w-full">
            <div id="google-map-card--0" className="absolute bottom-0 duration-300 h-[380px] md:h-[477px] inset-x-0 m-auto panel p-2 top-0 transition-all w-[260px] md:w-[320px] z-10 google-map-card">
                <div data-speed="1.15" className="h-full relative">
                    <div className="bg-blackPlaques-primary flex flex-col h-full justify-between  overflow-hidden">
                        <div className="absolute border-2 border-black flex h-[32px] md:h-[42px] items-center justify-center left-2 rounded-full text-black text-[22px] md:text-[32px] top-2 w-[32px] md:w-[42px]">1</div>
                        <div>
                            <button className='google-map-card__toggle' onClick={() => {
                                const mapCards = document.querySelectorAll('.google-map-card');
                                mapCards.forEach((mapCard) => {
                                    mapCard.classList.remove('opacity-100', 'translate-y-0');
                                    mapCard.classList.add('opacity-0', 'translate-y-8', 'pointer-events-none');
                                })
                            }}>Close</button>
                        </div>
                        <div className="flex flex-col font-serif h-[190px] md:h-[237px] italic justify-end leading-none py-1 px-2 text-[34px] md:text-[46px] text-white uppercase">
                            <div className="flex justify-end mr-3">
                                <span>Robert </span>
                            </div>
                            <div className="flex break-words">
                                <span>Wedder­burn</span>
                            </div>
                            <div className="my-1 tracking-wider text-[17px] md:text-[21px] text-black">
                                Politicial Activist and Abolitionist 
                            </div>
                        </div>
                        <div className="h-[190px] md:h-[239px] overflow-hidden relative w-full">
                            <div className="absolute bottom-0 font-serif inset-x-0 mx-auto p-5 pointer-events-none text-[28px] md:text-[36px] text-center text-white z-20">1762 — 1835</div>
                            <div className="h-full w-full image">
                                <div className="h-full relative w-full z-10 image-overlay">
                                    <StaticImage
                                        placeholder="blurred"
                                        alt="Black Plaques Project"
                                        className="h-full object-cover asset w-full"
                                        src="../../images/portfolio/black-plaques-project/black-plaques--banner-1.png"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="absolute bg-black -bottom-[70px] flex h-[70px] items-center justify-between left-0 p-4 w-full">
                            <button className="border border-blackPlaques-primary hover:border-white duration-300 px-4 py-2 rounded-full inline-block text-blackPlaques-primary transition-colors hover:text-white text-xs md:text-sm uppercase">Their story</button>
                            <button className="text-xs md:text-sm text-white uppercase">Visit location</button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="google-map-card--1" className="absolute bottom-0 duration-300 h-[380px] md:h-[477px] inset-x-0 m-auto opacity-0 translate-y-8 panel pointer-events-none p-2 top-0 transition-all w-[260px] md:w-[320px] z-10 google-map-card">
                <div data-speed="1.15" className="h-full relative">
                    <div className="bg-blackPlaques-primary flex flex-col h-full justify-between  overflow-hidden">
                        <div className="absolute border-2 border-black flex h-[32px] md:h-[42px] items-center justify-center left-2 rounded-full text-black text-[22px] md:text-[32px] top-2 w-[32px] md:w-[42px]">2</div>
                        <div>
                            <button className='google-map-card__toggle' onClick={() => {
                                const mapCards = document.querySelectorAll('.google-map-card');
                                mapCards.forEach((mapCard) => {
                                    mapCard.classList.remove('opacity-100', 'translate-y-0');
                                    mapCard.classList.add('opacity-0', 'translate-y-8', 'pointer-events-none');
                                })
                            }}>Close</button>
                        </div>
                        <div className="flex flex-col font-serif h-[190px] md:h-[237px] italic justify-end leading-none py-1 px-2 text-[34px] md:text-[46px] text-white uppercase">
                            <div className="flex justify-end mr-3">
                                <span>Ladipo</span>
                            </div>
                            <div className="flex break-words">
                                <span>Solanke</span>
                            </div>
                            <div className="my-1 tracking-wider text-[17px] md:text-[21px] text-black">
                                Activist 
                            </div>
                        </div>
                        <div className="h-[190px] md:h-[239px] overflow-hidden relative w-full">
                            <div className="absolute bottom-0 font-serif inset-x-0 mx-auto p-5 pointer-events-none text-[28px] md:text-[36px] text-center text-white z-20">1886 — 1958</div>
                            <div className="h-full w-full image">
                                <div className="h-full relative w-full z-10 image-overlay">
                                    <StaticImage
                                        placeholder="blurred"
                                        alt="Black Plaques Project"
                                        className="h-full object-cover asset w-full"
                                        src="../../images/portfolio/black-plaques-project/black-plaques--banner-2.png"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="absolute bg-black -bottom-[70px] flex h-[70px] items-center justify-between left-0 p-4 w-full">
                            <button className="border border-blackPlaques-primary hover:border-white duration-300 px-4 py-2 rounded-full inline-block text-blackPlaques-primary transition-colors hover:text-white text-xs md:text-sm uppercase">Their story</button>
                            <button className="text-xs md:text-sm text-white uppercase">Visit location</button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="google-map-card--2" className="absolute bottom-0 duration-300 h-[380px] md:h-[477px] inset-x-0 m-auto opacity-0 translate-y-8 panel pointer-events-none p-2 top-0 transition-all w-[260px] md:w-[320px] z-10 google-map-card">
                <div data-speed="1.15" className="h-full relative">
                    <div className="bg-blackPlaques-primary flex flex-col h-full justify-between  overflow-hidden">
                        <div className="absolute border-2 border-black flex h-[32px] md:h-[42px] items-center justify-center left-2 rounded-full text-black text-[22px] md:text-[32px] top-2 w-[32px] md:w-[42px]">3</div>
                        <div>
                            <button className='google-map-card__toggle' onClick={() => {
                                const mapCards = document.querySelectorAll('.google-map-card');
                                mapCards.forEach((mapCard) => {
                                    mapCard.classList.remove('opacity-100', 'translate-y-0');
                                    mapCard.classList.add('opacity-0', 'translate-y-8', 'pointer-events-none');
                                })
                            }}>Close</button>
                        </div>
                        <div className="flex flex-col font-serif h-[190px] md:h-[237px] italic justify-end leading-none py-1 px-2 text-[34px] md:text-[46px] text-white uppercase">
                            <div className="flex justify-end mr-3">
                                <span>Sarah</span>
                            </div>
                            <div className="flex break-words">
                                <span>Baartman</span>
                            </div>
                            <div className="my-1 tracking-wider text-[17px] md:text-[21px] text-black">
                                &nbsp; 
                            </div>
                        </div>
                        <div className="h-[190px] md:h-[239px] overflow-hidden relative w-full">
                            <div className="absolute bottom-0 font-serif inset-x-0 mx-auto p-5 pointer-events-none text-[28px] md:text-[36px] text-center text-white z-20">1789 — 1815</div>
                            <div className="h-full w-full image">
                                <div className="h-full relative w-full z-10 image-overlay">
                                    <StaticImage
                                        placeholder="blurred"
                                        alt="Black Plaques Project"
                                        className="h-full object-cover asset w-full"
                                        src="../../images/portfolio/black-plaques-project/black-plaques--banner-3.png"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="absolute bg-black -bottom-[70px] flex h-[70px] items-center justify-between left-0 p-4 w-full">
                            <button className="border border-blackPlaques-primary hover:border-white duration-300 px-4 py-2 rounded-full inline-block text-blackPlaques-primary transition-colors hover:text-white text-xs md:text-sm uppercase">Their story</button>
                            <button className="text-xs md:text-sm text-white uppercase">Visit location</button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="google-map-card--3" className="absolute bottom-0 duration-300 h-[380px] md:h-[477px] inset-x-0 m-auto opacity-0 translate-y-8 panel pointer-events-none p-2 top-0 transition-all w-[260px] md:w-[320px] z-10 google-map-card">
                <div data-speed="1.15" className="h-full relative">
                    <div className="bg-blackPlaques-primary flex flex-col h-full justify-between  overflow-hidden">
                        <div className="absolute border-2 border-black flex h-[32px] md:h-[42px] items-center justify-center left-2 rounded-full text-black text-[22px] md:text-[32px] top-2 w-[32px] md:w-[42px]">4</div>
                        <div>
                            <button className='google-map-card__toggle' onClick={() => {
                                const mapCards = document.querySelectorAll('.google-map-card');
                                mapCards.forEach((mapCard) => {
                                    mapCard.classList.remove('opacity-100', 'translate-y-0');
                                    mapCard.classList.add('opacity-0', 'translate-y-8', 'pointer-events-none');
                                })
                            }}>Close</button>
                        </div>
                        <div className="flex flex-col font-serif h-[190px] md:h-[237px] italic justify-end leading-none py-1 px-2 text-[34px] md:text-[46px] text-white uppercase">
                            <div className="flex justify-end mr-3">
                                <span>MIRIAM</span>
                            </div>
                            <div className="flex break-words">
                                <span>MAKEBA</span>
                            </div>
                            <div className="my-1 tracking-wider text-[17px] md:text-[21px] text-black">
                            MUSICIAN
                            </div>
                        </div>
                        <div className="h-[190px] md:h-[239px] overflow-hidden relative w-full">
                            <div className="absolute bottom-0 font-serif inset-x-0 mx-auto p-5 pointer-events-none text-[28px] md:text-[36px] text-center text-white z-20">1932 — 2008</div>
                            <div className="h-full w-full image">
                                <div className="h-full relative w-full z-10 image-overlay">
                                    <StaticImage
                                        placeholder="blurred"
                                        alt="Black Plaques Project"
                                        className="h-full object-cover asset w-full"
                                        src="../../images/portfolio/black-plaques-project/black-plaques--banner-4.png"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="absolute bg-black -bottom-[70px] flex h-[70px] items-center justify-between left-0 p-4 w-full">
                            <button className="border border-blackPlaques-primary hover:border-white duration-300 px-4 py-2 rounded-full inline-block text-blackPlaques-primary transition-colors hover:text-white text-xs md:text-sm uppercase">Their story</button>
                            <button className="text-xs md:text-sm text-white uppercase">Visit location</button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="google-map-card--4" className="absolute bottom-0 duration-300 h-[380px] md:h-[477px] inset-x-0 m-auto opacity-0 translate-y-8 panel pointer-events-none p-2 top-0 transition-all w-[260px] md:w-[320px] z-10 google-map-card">
                <div data-speed="1.15" className="h-full relative">
                    <div className="bg-blackPlaques-primary flex flex-col h-full justify-between  overflow-hidden">
                        <div className="absolute border-2 border-black flex h-[32px] md:h-[42px] items-center justify-center left-2 rounded-full text-black text-[22px] md:text-[32px] top-2 w-[32px] md:w-[42px]">5</div>
                        <div>
                            <button className='google-map-card__toggle' onClick={() => {
                                const mapCards = document.querySelectorAll('.google-map-card');
                                mapCards.forEach((mapCard) => {
                                    mapCard.classList.remove('opacity-100', 'translate-y-0');
                                    mapCard.classList.add('opacity-0', 'translate-y-8', 'pointer-events-none');
                                })
                            }}>Close</button>
                        </div>
                        <div className="flex flex-col font-serif h-[190px] md:h-[237px] italic justify-end leading-none py-1 px-2 text-[34px] md:text-[46px] text-white uppercase">
                            <div className="flex justify-end mr-3">
                                <span>Dyke</span>
                            </div>
                            <div className="flex break-words">
                                <span>Dryden & Wade</span>
                            </div>
                            <div className="my-1 tracking-wider text-[17px] md:text-[21px] text-black">
                            Entrepreneurs
                            </div>
                        </div>
                        <div className="h-[190px] md:h-[239px] overflow-hidden relative w-full">
                            <div className="h-full w-full image">
                                <div className="h-full relative w-full z-10 image-overlay">
                                    <StaticImage
                                        placeholder="blurred"
                                        alt="Black Plaques Project"
                                        className="h-full object-cover asset w-full"
                                        src="../../images/portfolio/black-plaques-project/black-plaques--banner-5.png"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="absolute bg-black -bottom-[70px] flex h-[70px] items-center justify-between left-0 p-4 w-full">
                            <button className="border border-blackPlaques-primary hover:border-white duration-300 px-4 py-2 rounded-full inline-block text-blackPlaques-primary transition-colors hover:text-white text-xs md:text-sm uppercase">Their story</button>
                            <button className="text-xs md:text-sm text-white uppercase">Visit location</button>
                        </div>
                    </div>
                </div>
            </div>
            
                <div className="bg-black h-full relative w-full z-0">
                    {isLoaded ? (
                        <GoogleMap
                            mapContainerStyle={
                                {
                                    width: '100%',
                                    height: '100%',
                                }
                            }
                            center={center}
                            zoom={15}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                            options={{
                                styles: darkMapStyle,
                                  disableDefaultUI: true,
                            }}
                        >
                            {markers.map((mark, index) => {

                                return (
                                    <Marker 
                                        key={mark.id} 
                                        position={mark}
                                        onClick={() => {
                                            const mapCards = document.querySelectorAll('.google-map-card');
                                            const currentMapCard = document.querySelector(`#google-map-card--${index}`);
                                            mapCards.forEach((mapCard) => {
                                                mapCard.classList.remove('opacity-100', 'translate-y-0');
                                                mapCard.classList.add('opacity-0', 'translate-y-8', 'pointer-events-none');
                                            })
                                            currentMapCard?.classList.add('opacity-100', 'translate-y-0');
                                            currentMapCard?.classList.remove('opacity-0', 'translate-y-8', 'pointer-events-none');
                                        }}
                                        icon={{
                                            url:`/black-plaque-project/black-plaques--pin-${index}.png`,
                                            scale: 7,
                                            size: new google.maps.Size(50, 58),
                                            scaledSize: new google.maps.Size(50, 58),
                                            origin: new google.maps.Point(0, 0),
                                            anchor: new google.maps.Point(25, 58),
                                            
                                        }}
                                    />
                                )
                            })}
                        </GoogleMap>
                    ) : (
                        <></>
                    )}
                    {/* <MapWithAMarker
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAGVwtJNzJuBEm9sLxIUBdyXxgNXOD6n_Q"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `100%` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        marks={[{ lat: 51.5134255, lng: -0.135384 }, { lat: 51.5403233, lng: -0.1399203 }, { lat: 51.5084592, lng: -0.1380165 }, { lat: 51.5110001, lng: -0.0642805 }, { lat: 51.4951012, lng: -0.1460569 }]}
                    /> */}
                </div>
        </div>
        
      
    </div>
</section>
                    </div>





                <ProjectTitle
                    background={'black'}
                    text={{ 
                        title: 'orange',
                    }}
                    content={[{
                      title: 'Immersive and informative platform',
                      body: [
                        "The project was launched with an immersive and informative platform that geo-mapped the locations and told the full stories behind each Black plaque.  What's unique is the interactive touch – billboards sport QR codes that unveil the complete stories of each honouree, providing a direct link to their rich legacies.",
                        "The campaign's exceptional impact and innovative approach have been widely recognized, leading it to secure a series of prestigious awards. Notably, the campaign clinched a Graphite Pencil in the Press & Outdoor category for Interactive Poster Adverts in 2021, a testament to its groundbreaking engagement. This recognition was further affirmed by not one but two Wood Pencil awards.  These accolades are a tribute to the campaign's ingenuity and its remarkable ability to resonate with audiences on multiple levels."
                      ]
                    }, {
                      title: 'Mayoral support',
                      body: [
                        "The initiative won the full support of the Mayor of London’s office which is hugely influential in bringing about social and policy change in the city. It was also covered extensively by Britain’s main commercial broadcaster - ITV, which serialised the unveiling of the first four plaques on the early evening news every Friday for a month. Thanks to overwhelming public support, English Heritage has responded positively and turned Winnifred Atwell’s temporary Black plaque into a permanent blue plaque."
                      ]
                    }]}
                    />

  <section data-background="black" className="bg-black relative text-white leading-none">
    <div data-speed="1.2" className="flex flex-wrap px-5 md:px-6 py-12 sm:container mx-auto">
      <div className="md:pr-4 mb-6 md:mb-8 w-full md:w-1/2">
        <StaticImage
          placeholder="blurred"
          alt="Black Plaques Project"
          src="../../images/portfolio/black-plaques-project/black-plaques--image-1.jpg"
        />
      </div>
      <div className="mr:pl-4 mb-6 md:mb-8 w-full md:w-1/2">
        <StaticImage
          placeholder="blurred"
          alt="Black Plaques Project"
          src="../../images/portfolio/black-plaques-project/black-plaques--image-2.jpg"
        />
      </div>
      <div className="md:pr-4 mb-6 md:mb-8 w-full md:w-1/2">
        <StaticImage
          placeholder="blurred"
          alt="Black Plaques Project"
          src="../../images/portfolio/black-plaques-project/black-plaques--image-3.jpg"
        />
      </div>
      <div className="mr:pl-4 mb-6 md:mb-8 w-full md:w-1/2">
        <StaticImage
          placeholder="blurred"
          alt="Black Plaques Project"
          src="../../images/portfolio/black-plaques-project/black-plaques--image-4.jpg"
        />
      </div>
      <div className="md:pr-4 w-full md:w-1/2">
        <StaticImage
          placeholder="blurred"
          alt="Black Plaques Project"
          src="../../images/portfolio/black-plaques-project/black-plaques--image-5.jpg"
        />
      </div>
      <div className="mr:pl-4 w-full md:w-1/2">
        <StaticImage
          placeholder="blurred"
          alt="Black Plaques Project"
          src="../../images/portfolio/black-plaques-project/black-plaques--image-6.jpg"
        />
      </div>
      
    </div>
  </section>
</div>
      <div data-background="black" className="h-half-screen"></div>
        </Layout>
    )
}

export default BlackPlaquePage
